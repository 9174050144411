import React, { useMemo, useState } from "react";
import { Button, DatePicker, Form, InputNumber, Select } from "antd";
import { RoundedDate } from "../styles/RoundedDate";
import { RoundedSelectStyle } from "../styles/RoundedSelect";
import { useTranslation } from "next-i18next";
import { VisitKsaUmrahTabStyle } from "./VisitKsaUmrahTabStyle";
import { useGetHotelDropdown } from "@queries/umrah/useHotels";
import { umrha_location_city } from "@common/constants";
import useCalcAllRooms from "@helpers/hooks/useCalAllRooms";
import PaxInfoDrawer from "@components/PaxInfoDrawer/PaxInfoDrawer";
import VisitKsaTabBtn from "../styles/VisitKsaTabBtn";
import PaxInfoModal from "@components/PaxInfoModal/PaxInfoModal";
import dayjs from "dayjs";
import { useRouter } from "next/dist/client/router";
import { RangePickerProps } from "antd/es/date-picker";
import useWidth from "@helpers/hooks/useWidth";
import { VisitKsaTabInputsStyle, VisitKsaInputErrorMessage } from "../styles/VisitKsaTabInputsStyle";
import { MdOutlineCalendarToday, MdOutlinePlace, MdOutlineBed } from "react-icons/md";

interface VisitKsaUmrahTabProps {
	onSearch: (values?: any) => void;
}

const { Option, OptGroup } = Select;
const VisitKsaUmrahTab: React.FC<VisitKsaUmrahTabProps> = ({ onSearch }) => {
	const { t, i18n } = useTranslation("website");
	const router = useRouter();
	const [hotelSearchKey, setHotelSearchKey] = useState<string>("");
	const { width } = useWidth();
	const [destination, setDestination] = useState<any>(null);
	const [formListOpen, setFormListOpen] = useState<boolean>(false);
	const onFormlistModalSave = () => {
		setFormListOpen(false);
		setRoomsValues(form.getFieldValue("roomAndTravelers"));
	};
	const [dates, setDates] = useState(null);
	const [form] = Form.useForm();
	const roomsWatcher = Form.useWatch("roomAndTravelers", form);
	const [roomsValues, setRoomsValues] = useState([
		{
			room_quantity: 1,
			ADT: 1,
			CHD: 0
		}
	]);

	const allRoomAndTravelersFieldsValue = useCalcAllRooms(roomsValues);
	const { data: hotelDropdownItems } = useGetHotelDropdown({ searchKey: hotelSearchKey });
	let isArabicLang = i18n.language === "ar";

	const selectedUmrahDes = useMemo(() => {
		//  return encodeURIComponent(JSON.stringify(hotelDropdownItems?.items?.find((el: any) => el?.name === destination?.children)?.providers));
		return hotelDropdownItems?.items?.find((el: any) => el?.name === destination?.children)?.providers;
	}, [destination]);
	const disabledDate: RangePickerProps["disabledDate"] = (current) => {
		return current && current < dayjs().startOf("day").add(4, "days");
	};

	const onFinish = (values: any) => {
		const roomFieldsWithChildAges = values?.roomAndTravelers ? values?.roomAndTravelers?.map((room: any) => ({ ...room, age: room?.age?.map((childAge: number | undefined) => childAge || 0) })) : "";
		let queryParams: any = { searchType: 1, isB2c: false };
		const [start, end] = dates || [null, null];
		if (start) queryParams.startDate = dayjs(start).locale("en").format("YYYY-MM-DD");
		if (end) queryParams.endDate = dayjs(end).locale("en").format("YYYY-MM-DD");
		if (destination) {
			queryParams.locationCode = destination?.value;
			queryParams.cityCode = destination?.cityCode;
			queryParams.type = destination?.type;
			queryParams.hotelProviders = encodeURIComponent(JSON.stringify(hotelDropdownItems?.items?.find((el: any) => el?.name === destination?.children)?.providers));
		}
		const roomsStr = encodeURIComponent(
			JSON.stringify(
				roomFieldsWithChildAges || [
					{
						room_quantity: 1,
						ADT: 1,
						CHD: 0
					}
				]
			)
		);
		queryParams.rooms = roomsStr;

		onSearch && onSearch(queryParams);
		router.push(
			{
				pathname: `/search/umrah`,
				query: queryParams
			},
			undefined,
			{ locale: isArabicLang ? "ar" : "en" }
		);
	};
	return (
		<VisitKsaUmrahTabStyle>
			<Form
				form={form}
				className="visit-ksa-umrah-tab-form"
				initialValues={{
					roomAndTravelers: [{ room_quantity: 1, ADT: 1, CHD: 0 }]
				}}
				onFinish={onFinish}
			>
				{(_, formInstance) => {
					return (
						<>
							<VisitKsaTabInputsStyle>
								<Form.Item
									noStyle
									name="destinations"
									rules={[
										{
											required: true
										}
									]}
								>
									<RoundedSelectStyle roundDir="left">
										<MdOutlinePlace style={{ fontSize: 20, color: "#949E89" }} />
										<Select
											placeholder={t("forms.fields.placeholders.destinations")}
											showArrow={false}
											showSearch
											filterOption={(input, option) => hotelDropdownItems}
											// value={destinations ? destination || destination?.value || userLocation?.countryName || destinations?.[0]?.id : null}
											value={destination?.value || destination?.cityCode || destination?.umrahHotelCode || null}
											onSearch={(val) => setHotelSearchKey(val)}
											onChange={(val: any, option: any) => {
												formInstance.setFieldValue("destinations", option);
												setDestination(option);
											}}
											// onSearch={debounce((val: string) => setDestinationsSearch(val), 300)}
											// loading={destinationsLoading || isSearchDestinationsLoading}
										>
											{!hotelSearchKey && (
												<OptGroup label={t("profile.createPackage.fields.common.label.City")}>
													{umrha_location_city?.map((s: any) => (
														<Option type={"Location"} value={s?.locationCode} cityCode={s?.locationCode}>
															{isArabicLang ? s?.city_ar : s?.city}
														</Option>
													))}
												</OptGroup>
											)}
											<OptGroup label={t("common.options.hotels")}>
												{hotelDropdownItems?.items?.map((s: any) => (
													<Option type="Hotel" value={"" + s?.umrahHotelCode} cityCode={s?.cityCode}>
														{s?.name}
													</Option>
												))}
											</OptGroup>
										</Select>
										{!formInstance.getFieldValue("destinations") && formInstance.getFieldError("destinations") && (
											<VisitKsaInputErrorMessage>{formInstance.getFieldError("destinations")}</VisitKsaInputErrorMessage>
										)}
									</RoundedSelectStyle>
								</Form.Item>
								<Form.Item
									name="date"
									noStyle
									rules={[
										{
											required: true
										}
									]}
								>
									<RoundedDate>
										<MdOutlineCalendarToday style={{ fontSize: 20, color: "#949E89" }} />
										<DatePicker.RangePicker
											placeholder={[t("profile.package.startDate"), t("profile.package.endDate")]}
											suffixIcon={null}
											onChange={(val: any) => {
												formInstance.setFieldValue("date", val);
												setDates(val);
											}}
											value={dates}
											disabledDate={disabledDate}
										/>
										{!formInstance.getFieldValue("date") && formInstance.getFieldError("date") && <VisitKsaInputErrorMessage>{formInstance.getFieldError("date")}</VisitKsaInputErrorMessage>}
									</RoundedDate>
								</Form.Item>
								<div className="visit-ksa-umrah-tab-rooms" onClick={() => setFormListOpen(true)}>
									<Form.Item name={"roomAndTravelers"} noStyle>
										<>
											<MdOutlineBed style={{ fontSize: 20, color: "#949E89" }} />
											<p>{`${allRoomAndTravelersFieldsValue?.room_quantity ?? 1} ${t("forms.fields.labels.rooms")}`},</p>
											<p>{`${allRoomAndTravelersFieldsValue?.ADT + allRoomAndTravelersFieldsValue?.CHD} ${t("forms.fields.labels.travelers")}`}</p>
										</>
									</Form.Item>
								</div>
								{width > 768 ? (
									<PaxInfoModal visible={formListOpen} onFormlistModalSave={onFormlistModalSave} form={form} onCancel={() => setFormListOpen(false)} />
								) : (
									<PaxInfoDrawer visible={formListOpen} onFormlistModalSave={onFormlistModalSave} form={form} onCancel={() => setFormListOpen(false)} />
								)}
								<VisitKsaTabBtn htmlType="submit" className="grid-btn" />
							</VisitKsaTabInputsStyle>

							<VisitKsaTabBtn htmlType="submit" className="main-btn" />
						</>
					);
				}}
			</Form>
		</VisitKsaUmrahTabStyle>
	);
};

export default VisitKsaUmrahTab;
