import styled from "@emotion/styled";
import { radiusInputCheck, sharedInputStyle } from "./shared";

export const RoundedDate = styled.div<{ width?: number; roundDir?: "left" | "right" | "both" }>`
	${sharedInputStyle}
	width: ${({ width }) => width ?? 276}px;
	border-radius: ${({ roundDir }) => radiusInputCheck(roundDir)};

	:lang(ar) {
		border-radius: ${({ roundDir }) => radiusInputCheck(roundDir === "left" ? "right" : roundDir === "right" ? "left" : roundDir)};
	}
	height: 40px;
	@media only screen and (max-width: 900px) {
		width: 100%;
		border-radius: ${({ roundDir }) => radiusInputCheck("both")} !important;
		&:lang(ar) {
			border-radius: ${({ roundDir }) => radiusInputCheck("both")} !important;
		}
	}

	.ant-picker-input input {
		&::placeholder {
			color: #343434;
		}
	}
	.ant-picker {
		@media only screen and (max-width: 768px) {
			border-radius: 20px !important;
		}
		width: 100%;
		border-radius: ${({ roundDir }) => radiusInputCheck(roundDir)} !important;
		border: none !important;
	}
	.ant-picker-focused {
		box-shadow: none !important;
	}
`;
