import { Dropdown } from "antd";
import React, { useState } from "react";

const DropdownContainer: React.FC<{ menu: React.ReactElement; trigger: ("click" | "hover" | "contextMenu")[]; className?: string; children: React.ReactNode }> = ({
	menu,
	trigger = ["click"],
	className,
	children
}) => {
	const [isOpen, setisOpen] = useState(false);
	window.addEventListener("scroll", () => {
		setisOpen(false);
	});
	return (
		<Dropdown overlay={menu} trigger={trigger} open={isOpen} onOpenChange={() => setisOpen(!isOpen)} overlayClassName={className} getPopupContainer={() => document.querySelector(".header-container")}>
			{children}
		</Dropdown>
	);
};

export default DropdownContainer;
