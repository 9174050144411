import styled from "@emotion/styled";
import { sharedInputStyle, showAnimation } from "../styles/shared";

export const VisitKsaTransportationTabStyle = styled.div`
	/* width: 1128px; */

	.visit-ksa-transportation-tab-form {
		height: 90px;
		background-color: #ffffff;
		padding: 20px;

		opacity: 0;
		border-radius: 36px;
		z-index: 20;
		display: flex;
		justify-content: space-between;
		align-items: center;
		${showAnimation}
		@media only screen and (max-width: 900px) {
			height: auto;
		}
		@media only screen and (max-width: 768px) {
			flex-direction: column;
			align-items: center;
			gap: 20px;
			height: auto;
			width: 100%;
		}
	}

	.vist-ksa-side-input {
		@media only screen and (max-width: 900px) {
			display: grid;
			grid-template-columns: 1fr min-content;
			grid-gap: 16px;
			width: 100%;
		}
		@media only screen and (max-width: 768px) {
			grid-template-columns: 1fr;
		}
	}
	.vechicle-popup {
		width: auto !important;
	}
	.visit-ksa-transportation-tab-vechicle-select {
		.ant-select-selection-item {
			div {
				display: none;
			}
		}
	}
	.grid-btn {
		display: none;
		@media only screen and (max-width: 900px) {
			display: flex;
		}
	}
	.main-btn {
		@media only screen and (max-width: 900px) {
			display: none;
		}
	}
`;
