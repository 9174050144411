import React, { useState } from 'react';
import { Button, DatePicker, Form, InputNumber, Radio, Select } from 'antd';
import { RoundedDate } from '../styles/RoundedDate';
import { RoundedSelectStyle } from '../styles/RoundedSelect';
import { useTranslation } from 'next-i18next';
import { VisitKsaVisaTabStyle } from './VisitKsaVisaTabStlye';
import { useGetHotelDropdown } from '@queries/umrah/useHotels';
import { umrahJourneyRadioOptions, umrha_location_city } from '@common/constants';
import useCalcAllRooms from '@helpers/hooks/useCalAllRooms';
import PaxInfoDrawer from '@components/PaxInfoDrawer/PaxInfoDrawer';
import VisitKsaTabBtn from '../styles/VisitKsaTabBtn';
import PaxInfoModal from '@components/PaxInfoModal/PaxInfoModal';
import dayjs from 'dayjs';
import { useRouter } from 'next/dist/client/router';
import { RangePickerProps } from 'antd/es/date-picker';
import useWidth from '@helpers/hooks/useWidth';
import { VisitKsaTabInputsStyle, VisitKsaInputErrorMessage } from '../styles/VisitKsaTabInputsStyle';
import { MdOutlineCalendarToday, MdOutlinePlace, MdOutlineBed } from 'react-icons/md';

interface VisitKsaUmrahTabProps {
	onSearch?: (values?: any) => void;
}

const { Option, OptGroup } = Select;
const VisitKsaVisaTab: React.FC<VisitKsaUmrahTabProps> = ({ onSearch }) => {
	const { t, i18n } = useTranslation('website');
	const router = useRouter();
	const { width } = useWidth();
	const [form] = Form.useForm();
	const [startDate, setStartDate] = useState<any>(null);
	let isArabicLang = i18n.language === 'ar';

	const disabledDate: RangePickerProps['disabledDate'] = (current) => {
		return current && current < dayjs().startOf('day').add(4, 'days');
	};

	const onFinish = (values: any) => {
		onSearch && onSearch();
		router.push(
			{
				pathname: '/umrah/start',
				query: {
					startDate: dayjs(startDate).locale('en').format('YYYY-MM-DD'), // read date value from state not form because form value is not updated when datepicker value is changed
					umrahJourneyOption: values?.umrahJourneyOption
				}
			},
			undefined,
			{ locale: isArabicLang ? 'ar' : 'en' }
		);
	};
	return (
		<VisitKsaVisaTabStyle>
			<Form
				form={form}
				className="visit-ksa-umrah-tab-form"
				initialValues={{
					roomAndTravelers: [{ room_quantity: 1, ADT: 1, CHD: 0 }]
				}}
				onFinish={onFinish}
			>
				{(_, formInstance) => {
					return (
						<>
							<VisitKsaTabInputsStyle className="visit-ksa-visa-tab-inputs">
								<Form.Item
									noStyle
									name="startDate"
									rules={[
										{
											required: true
										}
									]}
								>
									<RoundedDate width={250} roundDir="left">
										<MdOutlineCalendarToday style={{ fontSize: 20, color: '#949E89' }} />
										<DatePicker
											disabledDate={disabledDate}
											onChange={(date) => {
												formInstance.setFieldValue('startDate', date);
												setStartDate(date);
											}}
										/>
										{!formInstance.getFieldValue('startDate') && formInstance.getFieldError('startDate') && (
											<VisitKsaInputErrorMessage>{formInstance.getFieldError('startDate')}</VisitKsaInputErrorMessage>
										)}
									</RoundedDate>
								</Form.Item>
								<Form.Item
									noStyle
									name="umrahJourneyOption"
									rules={[
										{
											required: true
										}
									]}
								>
									<div className="visit-ksa-visa-tab-radio-wrapper">
										<Radio.Group buttonStyle="outline">
											<Radio value={umrahJourneyRadioOptions.makkahToMadina}>{t('forms.fields.labels.makkahToMadina')}</Radio>
											<Radio value={umrahJourneyRadioOptions.madinaToMakkah}>{t('forms.fields.labels.madinaToMakkah')}</Radio>
											<Radio value={umrahJourneyRadioOptions.makkahOnly}>{t('forms.fields.labels.makkahOnly')}</Radio>
										</Radio.Group>
										{!formInstance.getFieldValue('umrahJourneyOption') && formInstance.getFieldError('umrahJourneyOption') && (
											<VisitKsaInputErrorMessage>{formInstance.getFieldError('umrahJourneyOption')}</VisitKsaInputErrorMessage>
										)}
									</div>
								</Form.Item>
								<VisitKsaTabBtn htmlType="submit" className="visit-ksa-visa-tab-btn" />
							</VisitKsaTabInputsStyle>
						</>
					);
				}}
			</Form>
		</VisitKsaVisaTabStyle>
	);
};

export default VisitKsaVisaTab;
