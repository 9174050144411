import { Button, DatePicker, Form, Input, InputNumber, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { VisitKsaTourTripTabStyle } from "./VisitKsaTourTripStyle";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { useGetCurrentCountry, useGetDestinations, useGetDestinationsPaginated, useGetSearchDestinations } from "@queries/masterdata/useMasterdata";
import { useTranslation } from "next-i18next";
import { useSelector } from "react-redux";
import { useRouter } from "next/dist/client/router";
import dayjs from "dayjs";
import { useFetchSingleCountryHook } from "hooks/useFetchSingleCountry";
import { debounce } from "@helpers/assestsFunctions";
import { RoundedSelectStyle } from "../styles/RoundedSelect";
import { RoundedInputNumberStyle } from "../styles/RoundedInputNumber";
import { RoundedDate } from "../styles/RoundedDate";
import VisitKsaTabBtn from "../styles/VisitKsaTabBtn";
import type { RangePickerProps } from "antd/es/date-picker";
import useWidth from "@helpers/hooks/useWidth";
import { VisitKsaTabInputsStyle } from "../styles/VisitKsaTabInputsStyle";
import { MdOutlineCalendarToday, MdOutlineGroupAdd, MdOutlinePlace } from "react-icons/md";

interface VisitKsaTourTripProps {
	isInDrawer?: boolean;
	closeDrawer?: any;
	onSearch?: (values?: any) => void;
}

const VisitKsaTourTrip: React.FC<VisitKsaTourTripProps> = ({ isInDrawer, closeDrawer, onSearch }) => {
	// config
	const { t, i18n } = useTranslation("website");
	const router = useRouter();
	const { Option } = Select;
	const { width } = useWidth();

	// state
	const [count, setCount] = useState(1);
	const [destinationsSearch, setDestinationsSearch] = useState("");
	const [destination, setDestination] = useState<any>(null);
	const [dates, setDates] = useState<any>(null);
	const userLocation = useSelector((state: any) => state?.user?.userLocation);

	// queries
	const { data: destinations, isFetchingNextPage: destinationsLoading, fetchNextPage, refetch: refetchDestionations } = useGetDestinationsPaginated();
	const { data: currentCountry } = useFetchSingleCountryHook({
		destinations: destinations,
		countryId: userLocation?.countryId,
		name: "",
		onSuccess: (res) => {
			setDestination(res?.id);
		}
	});
	const { data: searchDestinationsItems, isLoading: isSearchDestinationsLoading } = useGetSearchDestinations({ name: destinationsSearch });

	const disabledDate: RangePickerProps["disabledDate"] = (current) => {
		return current && current < dayjs().startOf("day");
	};

	const onFinish = () => {
		let queryParams: any = {};
		const [start, end] = dates || [null, null];
		if (destination) {
			queryParams.destinationId = destination?.id || destination?.value || userLocation?.countryId;
			queryParams.destinationType = destination?.typeID;
		}
		if (start) queryParams.startDate = dayjs(start).locale("en").format("YYYY-MM-DD");
		if (end) queryParams.endDate = dayjs(end).locale("en").format("YYYY-MM-DD");
		if (count) queryParams.numberOfSeats = count;
		onSearch && onSearch(queryParams);
		router.push({
			pathname: "/p",
			query: queryParams
		});
	};

	useEffect(() => {
		refetchDestionations();
	}, [router?.locale]);

	return (
		<VisitKsaTourTripTabStyle isInDrawer={isInDrawer}>
			<VisitKsaTabInputsStyle>
				{/* <div className="visit-ksa-hero-bar-select" id="visitksa-select"> */}
				<RoundedSelectStyle roundDir="left">
					<MdOutlinePlace style={{ fontSize: 20, color: "#949E89" }} />
					<Select
						placeholder={t("forms.fields.placeholders.anyWhere")}
						showArrow={false}
						showSearch
						// optionFilterProp="children"
						// filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
						filterOption={(input, option) => searchDestinationsItems}
						value={destinations ? destination || destination?.value || userLocation?.countryName || destinations?.[0]?.id : null}
						onChange={(val: any, option: any) => setDestination(option)}
						onSearch={debounce((val: string) => setDestinationsSearch(val), 300)}
						loading={destinationsLoading || isSearchDestinationsLoading}
						// getPopupContainer={() => document.getElementById("visitksa-select")}
						// popupClassName="popup"
						onPopupScroll={(e) => {
							var target = e?.target as HTMLDivElement;
							if (!destinationsLoading && target?.scrollTop + target?.offsetHeight === target?.scrollHeight && destinationsSearch === "") {
								fetchNextPage();
							}
						}}
					>
						{currentCountry && destinationsSearch === "" && (
							<Option typeID={currentCountry?.typeID} key={currentCountry?.id} value={currentCountry?.id}>
								{currentCountry?.name || currentCountry?.countryName}
							</Option>
						)}
						{destinationsSearch !== ""
							? searchDestinationsItems?.map((des: any) => (
									<Option typeID={des?.typeID} key={des?.id} value={des?.id}>
										{des?.name || des?.countryName}
									</Option>
							  ))
							: destinations?.pages?.map((res) =>
									res?.responseData.map((s: any) => (
										<Option typeID={s?.typeID} key={s?.id} value={s?.id}>
											{s?.name || s?.countryName}
										</Option>
									))
							  )}
						{destinationsLoading && (
							<Option>
								<Spin />
							</Option>
						)}
					</Select>
				</RoundedSelectStyle>
				<RoundedDate roundDir={width > 900 ? undefined : "both"}>
					<MdOutlineCalendarToday style={{ fontSize: 20, color: "#949E89" }} />
					<DatePicker.RangePicker disabledDate={disabledDate} placeholder={[t("profile.package.startDate"), t("profile.package.endDate")]} suffixIcon={null} onChange={(val) => setDates(val)} />
				</RoundedDate>
				<RoundedInputNumberStyle roundDir={width > 900 ? "right" : "both"}>
					<MdOutlineGroupAdd style={{ fontSize: 20, color: "#949E89" }} />
					<InputNumber
						value={count}
						onChange={(value: any) => setCount(value)}
						addonBefore={
							<Button className="btn">
								<MinusCircleFilled
									disabled={count <= 1}
									onClick={() => {
										if (count > 1) {
											setCount((prev: number) => prev - 1);
										}
									}}
								/>
							</Button>
						}
						addonAfter={
							<Button className="btn">
								<PlusCircleFilled onClick={() => setCount((prev: number) => prev + 1)} />
							</Button>
						}
						placeholder="Passengers"
						min={1}
					/>
				</RoundedInputNumberStyle>
				<VisitKsaTabBtn className="grid-btn" onClick={onFinish} />
			</VisitKsaTabInputsStyle>
			<VisitKsaTabBtn  onClick={onFinish} className="main-btn" />
		</VisitKsaTourTripTabStyle>
	);
};

export default VisitKsaTourTrip;
