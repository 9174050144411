import { Tabs, TabsProps, Tooltip } from "antd";
import { useTranslation } from "next-i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import VisitKsaThingsToDoTab from "../VisitKsaThingsToDoTab/VisitKsaThingsToDoTab";
import VisitKsaTab from "../VisitKsaTourTripTab/VisitKsaTourTripTab";
import VisitKsaTransportationTab from "../VisitKsaTransportationTab/VisitKsaTransportationTab";
import VisitKsaUmrahTab from "../VisitKsaUmrahTab/VisitKsaUmrahTab";
import VisitKsaVisaTab from "../VisitKsaVisaTab/VisitKsaVisaTab";
import { VisitKsaTabsStyle } from "./VisitKsaTabsStyle";

interface VisitKsaTabsProps {
	className?: string;
	isInDrawer?: boolean;
	onClose?: () => void;
}

const VisitKsaTabs: React.FC<VisitKsaTabsProps> = ({ className, isInDrawer, onClose }) => {
	let { TabPane } = Tabs;
	const { t } = useTranslation("website");
	const [activeTab, setActiveTab] = useState("1");
	const { currentTheme } = useSelector((state: RootState) => state.user);
	const currentUser = useSelector((state: RootState) => state?.user?.currentUser);

	const baseTabs = [
		...(currentTheme?.features?.isPackageActive
			? [
					{
						key: "1",
						label: (
							<Tooltip title={t("common.Exclamation.package")}>
								{" "}
								<p onClick={() => setActiveTab("1")}>{t("profile.package.Packages")}</p>{" "}
							</Tooltip>
						),
						children: <VisitKsaTab isInDrawer={isInDrawer} onSearch={() => onClose && onClose()} />
					}
			  ]
			: []),
		...(currentTheme?.features?.isThingsToDoActive
			? [
					{
						key: "4",
						label: (
							<Tooltip title={t("common.Exclamation.activity")}>
								<p onClick={() => setActiveTab("4")}>{t("common.buttons.thingsToDo")}</p>
							</Tooltip>
						),
						children: <VisitKsaThingsToDoTab onSearch={() => onClose && onClose()} />
					}
			  ]
			: []),
		...(currentTheme?.features?.isUmrahJourneyActive
			? [
					{
						key: "5",
						label: (
							<Tooltip title={t("common.Exclamation.umrahJourney")}>
								<p onClick={() => setActiveTab("5")}>{`${t("common.buttons.umrahJourney")} + ${t("common.sentence.visa")}`}</p>
							</Tooltip>
						),
						children: <VisitKsaVisaTab />
					}
			  ]
			: [])
	];
	const [tabsArray, setTabsArray] = useState(baseTabs);
	// const items: TabsProps["items"] = [
	// 	{
	// 		key: "1",
	// 		label: <p onClick={() => setActiveTab("1")}>{t("profile.package.Packages")}</p>,
	// 		children: <VisitKsaTab isInDrawer={isInDrawer} onSearch={() => onClose && onClose()} />
	// 	},
	// 	{
	// 		key: "4",
	// 		label: <p onClick={() => setActiveTab("4")}>{t("common.buttons.thingsToDo")}</p>,
	// 		children: <VisitKsaThingsToDoTab onSearch={() => onClose && onClose()} />
	// 	},
	// 	...(process.env.MODE === "stg" || process.env.MODE === "dev"
	// 		? [
	// 				{
	// 					key: "2",
	// 					label: <p onClick={() => setActiveTab("2")}>{t("common.buttons.umrahStays")}</p>,
	// 					children: <VisitKsaUmrahTab onSearch={() => onClose && onClose()} />
	// 				}
	// 		  ]
	// 		: []),
	// 	...(process.env.MODE === "stg" || process.env.MODE === "dev"
	// 		? [
	// {
	// 	key: "3",
	// 	label: <p onClick={() => setActiveTab("3")}>{t("common.buttons.umrahTransportation")}</p>,
	// 	children: <VisitKsaTransportationTab onSearch={() => onClose && onClose()} />
	// }
	// 		  ]
	// 		: [])
	// ];

	useEffect(() => {
		if (currentUser && currentUser?.userType === 3) {
			setTabsArray((prev: any) => [
				...prev,
				...(currentTheme?.features?.isUmrahB2BActive
					? [
							{
								key: "2",
								label: (
									<Tooltip title={t("common.Exclamation.stays")}>
										<p onClick={() => setActiveTab("2")}>{t("common.buttons.umrahStays")}</p>
									</Tooltip>
								),
								children: <VisitKsaUmrahTab onSearch={() => onClose && onClose()} />
							}
					  ]
					: []),
				...(currentTheme?.features?.isUmrahB2BActive
					? [
							{
								key: "3",
								label: (
									<Tooltip title={t("common.Exclamation.transportation")}>
										<p onClick={() => setActiveTab("3")}>{t("common.buttons.umrahTransportation")}</p>
									</Tooltip>
								),
								children: <VisitKsaTransportationTab onSearch={() => onClose && onClose()} />
							}
					  ]
					: [])
			]);
		} else {
			setTabsArray(baseTabs);
		}
	}, [currentUser, currentTheme]);
	return (
		<VisitKsaTabsStyle size={activeTab !== "3" ? "normal" : "large"} className={className} isInDrawer={isInDrawer}>
			<Tabs activeKey={activeTab} items={[...new Map(tabsArray?.map((item) => [item?.["key"], item])).values()]}></Tabs>
		</VisitKsaTabsStyle>
	);
};

export default VisitKsaTabs;
