import styled from "@emotion/styled";
import { sharedInputStyle, showAnimation } from "../styles/shared";

export const VisitKsaUmrahTabStyle = styled.div`
	.visit-ksa-umrah-tab-form {
		height: 90px;
		background-color: #ffffff;
		padding: 20px;

		${showAnimation}
		border-radius: 36px;
		z-index: 20;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media only screen and (max-width: 900px) {
			height: auto;
		}
		@media only screen and (max-width: 768px) {
			flex-direction: column;
			align-items: center;
			gap: 20px;
			height: auto;
			width: 100%;
		}
		.visit-ksa-tab-inputs {
			display: flex;
			@media only screen and (max-width: 900px) {
				display: grid;
				grid-template-columns: 1fr 1fr;
				justify-items: end;
				gap: 16px;

				.grid-btn {
					display: flex;
				}
			}
			@media only screen and (max-width: 768px) {
				grid-template-columns: 1fr;
			}
		}
	}
	.visit-ksa-umrah-tab-rooms {
		${sharedInputStyle}
		border-radius: 0px 20px 20px 0px;
		&:lang(ar) {
			border-radius: 20px 0px 0px 20px;
		}
		min-width: 253px;
		width: auto;
		gap: 10px;
		p {
			font-size: 14px;
		}
		@media only screen and (max-width: 900px) {
			width: 100%;
			border-radius: 20px;
		}
	}

	.grid-btn {
		display: none;
		@media only screen and (max-width: 900px) {
			display: flex;
		}
	}
	.main-btn {
		@media only screen and (max-width: 900px) {
			display: none;
		}
	}

	.visit-ksa-umrah-tab-rooms {
		height: 40px;
	}
`;
