import DialogContainer from "@components/dialog/DialogContainer";
import SignInContent from "@components/auth/SignInContent";
import RegisterContent from "@components/auth/RegisterContent";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { useAppDispatch } from "store/configureStore";
import { changeAuthType, toggleAuthModal } from "store/reducers/authentication/authentication";

const AuthModal = () => {
	const isVisible = useSelector((state: RootState) => state?.auth.isModalOpen);
	const isRegister = useSelector((state: RootState) => state?.auth?.isRegister);
	const dispatch = useAppDispatch();
	const toggleDialog = () => {
		dispatch(toggleAuthModal(!isVisible));
	};

	const toggleAuthType = () => {
		dispatch(changeAuthType());
	};

	return (
		<DialogContainer title={""} visible={isVisible} toggleDialog={toggleDialog} style="">
			{isRegister ? <RegisterContent toggleAuthType={toggleAuthType} /> : <SignInContent toggleAuthType={toggleAuthType} />}
		</DialogContainer>
	);
};

export default AuthModal;
