import React, { useState } from "react";
import { setUsedCurrency } from "store/reducers/user";
import { Modal, Button } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import useLocalState from "@components/router/useLocalState";

interface Currency {
	abbreviation: string;
	code: string;
	id: number;
	name: string;
}
const CurrencyModal: React.FC<{ translate: { t: any; i18n: any }; className?: string }> = ({ translate, className }) => {
	const { localConditions, localState } = useLocalState();
	// Configs
	const { t } = translate;
	const dispatch = useDispatch();
	const router = useRouter();

	// Local Storage data
	const localCurrency = localStorage?.getItem("UserCurrency") && localStorage?.getItem("UserCurrency") !== "undefined" ? JSON?.parse(localStorage?.getItem("UserCurrency") || "{}") : null;

	// States
	const [isOpen, setIsOpen] = useState<boolean>(false);

	// Methods
	const showModal = () => {
		setIsOpen(true);
	};

	const handleOk = () => {
		setIsOpen(false);
	};

	const handleCancel = () => {
		setIsOpen(false);
	};

	const toggleDialog = () => {
		setIsOpen(!isOpen);
	};
	// Handlers for currency selection and set it to local storage and redux store
	const selectCurrencyHandler = (code: any) => {
		const curr = localState?.currencyList?.find((currency: Currency) => currency?.code === code);
		localStorage.setItem("UserCurrency", JSON?.stringify(curr));
		dispatch(setUsedCurrency(curr));
		setIsOpen(false);
	};

	// Components
	const suggestedCurrencies = localState?.currencyList
		?.filter((cur: any) => cur?.isFavorite)
		?.map((item: Currency) => (
			<CurrencyItem
				key={item.id}
				item={item}
				selectCurrency={() => {
					selectCurrencyHandler(item.code);
					router.reload();
					toggleDialog();
				}}
			/>
		));

	const allCurrencies = localState?.currencyList?.map((item: Currency) => (
		<CurrencyItem
			key={item.id}
			item={item}
			selectCurrency={() => {
				selectCurrencyHandler(item.code);
				setUsedCurrency(item);
				toggleDialog();
			}}
		/>
	));

	// Render
	return (
		<>
			<Button className={"currency-lang-btn " + className} onClick={showModal}>
				{localCurrency?.abbreviation}
			</Button>
			<Modal open={isOpen} title={t("forms.fields.labels.selectCurrency")} centered className="currencyModalStyle" onOk={handleOk} onCancel={handleCancel} footer={null}>
				<div className="modal_content">
					{localState?.currencyList?.some((cur: any) => cur?.isFavorite) && (
						<div className="currencies_list">
							<h3 className="sub_title">{t("forms.fields.labels.suggestedCurrencies")}</h3>
							<div className="currencies">{suggestedCurrencies}</div>
						</div>
					)}
					<div className="currencies_list">
						<h3 className="sub_title">{t("forms.fields.labels.allCurrencies")}</h3>
						<div className="currencies">{allCurrencies}</div>
					</div>
				</div>{" "}
			</Modal>
		</>
	);
};

const CurrencyItem: React.FC<{ item: Currency; selectCurrency: (ev: React.MouseEvent<HTMLButtonElement>) => void }> = ({ item, selectCurrency }) => {
	const selected = localStorage?.getItem("UserCurrency") && localStorage?.getItem("UserCurrency") !== "undefined" ? JSON?.parse(localStorage?.getItem("UserCurrency") || "{}") : null;
	const { name, code, id, abbreviation } = item;
	// const isSelected = false;
	const isSelected = selected?.code === code;

	return (
		<button className={`currency_item ${isSelected ? "active" : ""}`} onClick={selectCurrency} value={code}>
			<p className="currency_name">{name}</p>
			<p className="currency_abbr">
				{abbreviation}
				{isSelected && <CheckCircleFilled style={{ fontSize: 20 }} />}
			</p>
		</button>
	);
};

export default CurrencyModal;
