import { SaudiArabiaFlag, UnitedKingdomFlag } from "@common/icons";
import { useRouter } from "next/router";
import { useAppDispatch } from "store/configureStore";
import { resetMasterData } from "store/reducers/masterdata";
import { Menu, Space } from "antd";
import DropdownContainer from "../dropdown/DropdownContainer";
import Languages from "@components/shared/Languages.json";
import { TbWorld } from "react-icons/tb";
import { useEffect } from "react";
import { setLang } from "store/reducers/user";
export const LanguageDropdown: React.FC<{ translate: any }> = ({ translate }) => {
	const dispatch = useAppDispatch();
	const router = useRouter();
	const { pathname, asPath, query } = router;
	const { i18n, t } = translate;
	const isArabicLang = i18n.language === "ar";
	const currentLang = Languages.find((lang) => lang.code === i18n.language);
	const handleClick = ({ key }: any) => {
		i18n.changeLanguage(key);
		localStorage.setItem("UserLanguage", key);
		dispatch(setLang(key));
		router.replace({ pathname, query }, asPath, { locale: key });
		dispatch(resetMasterData());
		localStorage.removeItem("CurrencyList");
	};

	useEffect(() => {}, []);

	const menu = (
		<Menu
			items={[
				{
					label: t("common.header.CountryRegion"),
					key: "0"
				},
				...(Languages?.filter((key) => key.code !== i18n.language)?.map((item) => ({
					key: item.code,
					// icon: getIconByName(item.icon),
					label: item.name,
					disabled: false
				})) || [])
			]}
			onClick={handleClick}
		/>
	);

	return (
		<DropdownContainer menu={menu} trigger={["click"]} className="lang_dropdown">
			<div className="LangButton">
				<Space className="AuthHeader_Lang_Holder" size="small">
					<TbWorld className="main-color" style={{ fontSize: 22 }} />
					{currentLang?.name}
				</Space>
			</div>
		</DropdownContainer>
	);
};
