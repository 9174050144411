import { signIn } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useCallback, useState } from "react";
import { useAppDispatch } from "store/configureStore";
import { fieldsRegisterArray } from "@helpers/fields";
import { toggleAuthModal } from "store/reducers/authentication/authentication";
import ProvidersAuth from "./ProvidersAuth/ProvidersAuth";
import RegisterForm from "./RegisterForm/RegisterForm";
import Link from "next/link";
import { Button } from "antd";
import { RootState } from "store/rootReducer";
import { useSelector } from "react-redux";

const RegisterContent = ({ toggleAuthType }: any) => {
	const { t, i18n } = useTranslation("website");
	const dispatch = useAppDispatch();
	const { currentTheme } = useSelector((state: RootState) => state.user);
	// const [customLoadingState, setCustomLoadingState] = useState(false);
	const handleUserTypeRoute = (type: number) => {
		if (+type === 0) return "/admin/security/admins";
		else if (+type === 1) return "/admin/security/admins";
		else if (+type === 2) return "/";
	};

	const handleProviders = useCallback((type: string) => {
		let userType = 2;
		// setCustomLoadingState(true);
		signIn(type, {
			redirect: false,
			callbackUrl: handleUserTypeRoute(userType),
			userType
		});
		dispatch(toggleAuthModal(false));
	}, []);

	return (
		<div className="AuthBox_Register">
			<h1 className="RegisterBox_Title">
				{t("forms.titles.welcome")} {currentTheme?.tenantName}
				{/* {t(`forms.titles.welcome`, {
					host: ""
				})} */}
			</h1>
			<RegisterForm userType={2} fieldsArr={fieldsRegisterArray} submitBtnKey="signUp" />
			<div className="RegisterBox_SignUp" onClick={() => toggleAuthType()}>
				{t("forms.others.haveAccount")}
				<Button type="link">
					<Link href="/auth/register">{t("common.buttons.signUpHere")}</Link>
				</Button>
			</div>
			{/* {currentTheme?.tenantID?.toLowerCase() === "5d33b7e6-eabe-4c17-1f9a-08db1410efcc" && (
				<>
					<div className="RegisterBox_OtherSign">
						<span className="RegisterBox_OtherSign1_Span">{t("forms.others.orSign")}</span>
					</div>
					<ProvidersAuth handleProviders={handleProviders} />
				</>
			)} */}
		</div>
	);
};

export default RegisterContent;
