import styled from "@emotion/styled";

const ForgetPassword = styled.div`
    .input-holder .ant-input-affix-wrapper:lang(ar) {
        direction: rtl !important;
    }
    .e-Error {
        color: red;
    }
    .input-holder {
        margin-bottom: 1rem;
    }
    .LoginBox_Title {
        width: 100%;
        color: $vibrarnt-black;
        margin: 0px 0px 25px 0px;
        font-size: 28px;
        @media (max-width: 992px) {
            font-size: 22px;
        }
        font-weight: bold;
    }
`;
export default ForgetPassword;
