import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useSelector } from "react-redux";
import { login } from "@controllers/state/auth/actions";
import { useEffect, useState } from "react";
import { FacebookIcon, GoogleIcon } from "@common/icons";
import { fetchUserInfo } from "store/reducers/user";
import { useAppDispatch } from "store/configureStore";
import styles from "./AuthBox.module.scss";
import { Button, Form, Input } from "antd";
import ForgetPassword from "./style/ForgetPassword";
import AuthFormStyle from "./style/AuthFormStyle";
import Link from "next/link";
import { uTypes } from "@components/profile/package/types/UserTypes";
import { RootState } from "store/rootReducer";

const AuthBox: React.FC<{ csrfToken: any; toggleAuthType?: any; isModal?: boolean }> = ({ csrfToken, toggleAuthType, isModal }) => {
	let { auth }: any = useSelector((state) => state);
	const { t, i18n } = useTranslation("website");
	const dispatch = useAppDispatch();
	const [customLoadingState, setCustomLoadingState] = useState(false);
	const [registerBussinessAppear, setRegisterBussinessAppear] = useState(false);
	const { currentTheme } = useSelector((state: RootState) => state.user);
	const isArabicLang = i18n.language === "ar";
	const router = useRouter();
	const ISSERVER = typeof window === "undefined";
	let isThereRedirectPath: any = ISSERVER ? null : localStorage.getItem("on_login_redirect_path");

	const handleSubmit = async (dataItem: { [name: string]: any }) => {
		setCustomLoadingState(true);
		let body = {
			userName: dataItem?.userName?.trim(),
			password: dataItem?.password,
			userType: 2,
			clientId: "WebSite",
			clientSecret: process.env.NEXT_PUBLIC_CLIENT_SECRET,
			deviceToken: "string",
			deviceId: "string"
		};
		// console.log(window.location, "window.location");
		// return false;
		await dispatch(
			login(
				body,
				(res: any) => {
					const url = window.location.origin;
					console.log(res, "ressssss");
					dispatch(fetchUserInfo(res.responseData?.accessToken)).then(({ payload }: any) => {
						// return false;
						const { dateOfBirth, isUserProfileCompleted, phoneNumber, countryCode, roles, ...resetPayload } = res.responseData;
						setCustomLoadingState(false);
						signIn("Credentials", {
							...(+payload?.userType === uTypes.SuAdmin || +payload?.userType === uTypes.Admin
								? {
										callbackUrl: `${url}${isArabicLang ? "/ar" : ""}/admin/security/admins`
								  }
								: {
										...(!(+payload?.userType === uTypes.SuAdmin || +payload?.userType === uTypes.Admin) &&
											isThereRedirectPath && { callbackUrl: isArabicLang ? `${url}/ar` + isThereRedirectPath : url + isThereRedirectPath }),
										...(!isThereRedirectPath && {
											callbackUrl:
												+payload?.userType === 3
													? payload?.isCompanyProfileCompleted
														? res?.responseData?.roles?.some((role: any) => role === "Dashboard")
															? `${url}/${router.locale}/profile/dashboard`
															: `${url}/${router.locale}/profile/personal-details`
														: `${url}/${router.locale}/profile/personal-details`
													: isArabicLang
													? "/ar/"
													: "/"
										})
								  }),
							...resetPayload
						});
					});
				},
				(err: any) => {
					setCustomLoadingState(false);
				}
			)
		);
	};
	const handleProviders = (type: string) => {
		const url = window.location.origin;
		setCustomLoadingState(true);
		let isThereRedirectPath: any = ISSERVER ? null : localStorage.getItem("on_login_redirect_path");
		signIn(type, {
			...(!isThereRedirectPath && {
				callbackUrl: false
					? false
						? false
							? `${url}/${router.locale}/profile/dashboard`
							: `${url}/${router.locale}/profile/personal-details`
						: `${url}/${router.locale}/profile/personal-details`
					: "/"
			})
		});
		setCustomLoadingState(false);
	};

	useEffect(() => {
		if (router?.query?.listYourService) setRegisterBussinessAppear(true);
	});

	return (
		<div className={`AuthBox_form ${styles.AuthBox_Login} ${isModal ? styles.modal_box : ""}`}>
			<>
				<AuthFormStyle>
					<h1 className="LoginBox_Title">{t("forms.titles.welcomeBack")}</h1>
					<Form layout="vertical" name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} initialValues={{ remember: true }} onFinish={handleSubmit} autoComplete="off">
						<Form.Item className="input-holder" label={t("forms.fields.labels.userName")} name="userName" rules={[{ required: true }]}>
							<Input />
						</Form.Item>

						<Form.Item className="input-holder" label={t("forms.fields.labels.password")} name="password" rules={[{ required: true }]}>
							<Input.Password />
						</Form.Item>
						{/* <input name="csrfToken" type="hidden" defaultValue={csrfToken} /> */}
						<ForgetPassword onClick={() => router.push("/auth/forget_password", "/auth/forget_password", { locale: isArabicLang ? "ar" : "en" })} className="LoginBox_Forget">
							{t("forms.others.forgetPassword")}
						</ForgetPassword>
						{(auth?.error[0] || router.query.error) && (
							<p className="e-Error" style={{ margin: 0 }}>
								{auth?.error[0] || router.query.error}
							</p>
						)}
						<Button loading={auth?.loading || customLoadingState} type="primary" htmlType="submit" className={styles.LoginBox_Save}>
							<div>{t("common.buttons.signIn")}</div>
						</Button>
					</Form>
				</AuthFormStyle>

				<div className={styles.LoginBox_SignUp}>
					{t("forms.others.dontHaveAccount")}
					{registerBussinessAppear ? (
						<Button type="link">
							<Link href="/auth/register_business">{t("common.buttons.signUp")}</Link>
						</Button>
					) : (
						<Button type="link">
							<Link href="/auth/register">{t("common.buttons.signUp")}</Link>
						</Button>
					)}
				</div>
				{/* {currentTheme?.tenantID?.toLowerCase() === "5d33b7e6-eabe-4c17-1f9a-08db1410efcc" && (
					<>
						<div className={styles.LoginBox_OtherSign}>
							<span className={styles.LoginBox_OtherSign1_Span}>{t("forms.others.orSign")}</span>
						</div>
						<div className={styles.LoginBox_Continue} onClick={() => handleProviders("google")}>
							<GoogleIcon />
							<h1 className={styles.LoginBox_Continue_Text}>{t("common.buttons.continueGoogle")}</h1>
							<div className={styles.LoginBox_Continue_Extra} />
						</div>
						<div className={styles.LoginBox_Continue} onClick={() => handleProviders("facebook")}>
							<FacebookIcon />
							<h1 className={styles.LoginBox_Continue_Text}>{t("common.buttons.continueFacebook")}</h1>
							<div className={styles.LoginBox_Continue_Extra} />
						</div>
					</>
				)} */}
			</>
		</div>
	);
};

export default AuthBox;
