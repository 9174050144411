import Link from "next/link";
import styled from "@emotion/styled";
import useWidth from "@helpers/hooks/useWidth";
import { useRouter } from "next/router";
import { useState, useRef, useEffect } from "react";
import { useAppDispatch } from "store/configureStore";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { useTranslation } from "next-i18next";
import { fetchUserInfo, setCurrentUser, setDrawerOpen } from "store/reducers/user";
import { EmailConfirmationUsingURL } from "@controllers/state/auth/actions";
import { servicesArray, tabsArray } from "@helpers/fields";
import { Call } from "@common/icons";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, message, Space, Divider, Input, Drawer, Badge, Dropdown, Menu, MenuProps } from "antd";

// Components
import ImageCustom from "@components/ImageCustom";
import AuthModal from "@components/auth/AuthModal";
import CurrencyModal from "@components/modal/CurrencyModal";
import ProfileDropdown from "@components/dropdown/ProfileDropdown";
import VisitKsaTab from "@components/visitKsa/VisitKsaHero/VisitKsaTourTripTab/VisitKsaTourTripTab";
import { LanguageDropdown } from "@components/dropdown/LanguageDropdown";
import { VisitKsaArabicLogo, VisitKsaLogo, VisitKsaSearchIcon } from "@components/visitKsa/VisitKsaIcon";
import VisitKsaTabs from "@components/visitKsa/VisitKsaHero/VisitKsaTabs/VisitKsaTabs";
import VisitKsaToolbar from "@components/visitKsa/VisitKsaToolbar/VisitKsaToolbar";
import { MdOutlineShoppingCart, MdShoppingCart, MdTravelExplore } from "react-icons/md";
function Header({ sectionLeft = true, isLastStep, user, isAdmin = false, showToolbar = false, hideOtaBtn }: any) {
	// Config
	const { currentTheme } = useSelector((state: RootState) => state.user);
	const router = useRouter();
	const translate = isAdmin ? useTranslation("admin") : useTranslation("website");
	const dispatch = useAppDispatch();
	
	// Constants
	const { width } = useWidth();
	const { t, i18n } = translate;
	const isArabicLang = i18n.language === "ar";
	
	// State
	const [visitKsaDrawerOpen, setVisitKsaDrawerOpen] = useState(false);
	
	const [showEmailSendPopup, setshowEmailSendPopup] = useState(false);
	const [isMenuVisible, setisMenuVisible] = useState(false);
	
	// Selector
	const currentUser = useSelector((state: RootState) => state?.user?.currentUser);
	console.log(currentUser, "currentUser");
	// Ref
	const resMenuRef = useRef<HTMLDivElement>(null);
	
	// Watchers
	useEffect(() => {
		if (!currentUser && user) dispatch(fetchUserInfo(user?.accessToken));
		if (currentUser && !user) dispatch(setCurrentUser(null));
	}, []);
	
	useEffect(() => {
		setDrawerOpen(false);
	}, [router.asPath]);
	
	// Handlers
	const loginFunction = () => {
		router.push(
			{
				pathname: `/auth/login`,
				query: { forceBack: router.asPath }
			},
			`/auth/login`,
			{ locale: isArabicLang ? "ar" : "en" }
			);
		};
		
		const partnerClickHandler = () => {
			resMenuRef!.current?.classList.remove("active");
			setTimeout(() => {
				setisMenuVisible(false);
			}, 500);
			if (currentUser) router.push("/profile/packages", undefined, { locale: isArabicLang ? "ar" : "en" });
			else router.push("/partner", undefined, { locale: isArabicLang ? "ar" : "en" });
		};
		
		const handelResendVerfiy = () => {
			dispatch(
				EmailConfirmationUsingURL(
					(res: any) => {
						if (res?.responseCode === 200) {
							setshowEmailSendPopup(true);
						}
					},
					(err: any) => {
						message.error(err?.response?.data?.responseMessage);
					}
					)
					);
				};
				
				// Main Components
				const MobileView = () => {
					return (
						<>
						{width <= 992 && isMenuVisible && (
							<div className="home-res-menu" ref={resMenuRef}>
							{currentUser ? (
								<div className="menu-conetnt-holder">
								<div className="res-menu-header">
								<div className="res-menu-header-logo">
								<Link passHref href={"/"}>
								<ImageCustom src={isArabicLang ? `${`https://i.ibb.co/FXxtjQT/logo.png`}` : `${`https://i.ibb.co/FXxtjQT/logo.png`}`} alt={currentTheme?.tenantName} />
								</Link>
								</div>
								<Button
								icon={<CloseOutlined />}
								onClick={() => {
									resMenuRef!.current?.classList.remove("active");
									setTimeout(() => {
										setisMenuVisible(false);
									}, 500);
								}}
								className="close_button"
								/>
								</div>
								
								<Divider style={{ margin: 0 }} />
								
								<div className="preferences_section">
								<CurrencyModal translate={translate} />
								<LanguageDropdown translate={translate} />
								</div>
								
								<div className="menu-section-list">
								<div className="tabs-list-holder">
								<ul>
								{tabsArray(currentUser)?.map((tab) => (
									<li
									onClick={() => {
										resMenuRef!.current?.classList.remove("active");
										setTimeout(() => {
											setisMenuVisible(false);
										}, 500);
									}}
									>
									<Link href={tab.link}>
									<tab.icon />
									{t(`common.buttons.${tab.name}`)}
									{tab?.soon && <span>{t("sections.carousal.soon")}</span>}
									</Link>
									</li>
									))}
									</ul>
									</div>
									
									<CustomDivider />
									
									<div className="services-list-holder">
									<ul>
									{servicesArray.map((tab) => (
										<li>
										<Link href={tab.link}>{t(`sections.footer.${tab.name}`)}</Link>
										</li>
										))}
										</ul>
										</div>
										<CustomDivider />
										<div className="contact-holder">
										<h3>{t("sections.footer.needOurHelp")}</h3>
										<div className="contact-details-container">
										<Call />
										<div className="contact-details">
										<p className="phone">+966 12 650 5555</p>
										<p className="email">info@dyafat.com</p>
										</div>
										</div>
										</div>
										</div>
										</div>
										) : (
											<>
											<Button
											icon={<CloseOutlined />}
											onClick={() => {
												resMenuRef!.current?.classList.remove("active");
												setTimeout(() => {
													setisMenuVisible(false);
												}, 500);
											}}
											className="close_button"
											style={{ marginLeft: "auto", display: "block" }}
											/>
											<div className="preferences_section" style={{ marginTop: 30 }}>
											<CurrencyModal translate={translate} />
											<LanguageDropdown translate={translate} />
											</div>
											<Space direction="vertical" style={{ width: "100%", marginTop: 20 }} size={15}>
											<Button type="primary" onClick={loginFunction}>
											{t("common.header.Login")}
											</Button>
											{!hideOtaBtn && (
												<Button type="primary" ghost onClick={partnerClickHandler}>
												{t("common.header.listyourservice")}
												</Button>
												)}
												</Space>
												</>
												)}
												</div>
												)}
												</>
												);
											};
											
											// Render
											return (
												<>
												<div className="header-container">
												<DesktopView
												setisMenuVisible={setisMenuVisible}
												sectionLeft={sectionLeft}
												isLastStep={isLastStep}
												currentTheme={currentTheme}
												isArabicLang={isArabicLang}
												currentUser={currentUser}
												partnerClickHandler={partnerClickHandler}
												loginFunction={loginFunction}
												translate={translate}
												isAdmin={isAdmin}
												visitKsaDrawerOpen={visitKsaDrawerOpen}
												hideOtaBtn={hideOtaBtn}
												setVisitKsaDrawerOpen={setVisitKsaDrawerOpen}
												/>
												<AlertComponent showEmailSendPopup={showEmailSendPopup} currentUser={currentUser} handelResendVerfiy={handelResendVerfiy} />
												<MobileView />
												{!currentUser && <AuthModal />}
												{showToolbar && <VisitKsaToolbar />}
												</div>
												</>
												);
											}
											
											// Sub Components
											const LeftSection = ({ sectionLeft, isLastStep, currentTheme, isArabicLang }: any) => {
												return (
													<>
													{sectionLeft || isLastStep ? (
														<div className="Header_Left">
														<div className="Header_Brand">
														{currentTheme?.businessStore ? (
															<a href={"https://" + currentTheme?.domainName}>
															<ImageCustom src={isArabicLang ? `${`https://i.ibb.co/FXxtjQT/logo.png`}` : `${`https://i.ibb.co/FXxtjQT/logo.png`}`} alt={currentTheme?.tenantName} />
															</a>
															) : (
																<Link passHref href={"/"}>
																<ImageCustom src={isArabicLang ? `${`https://i.ibb.co/FXxtjQT/logo.png`}` : `${`https://i.ibb.co/FXxtjQT/logo.png`}`} alt={currentTheme?.tenantName} />
																</Link>
																)}
																</div>
																</div>
																) : null}
																</>
																);
															};
															
															const AlertComponent = ({ showEmailSendPopup, currentUser, handelResendVerfiy }: any) => {
																const router = useRouter();
																const { t } = useTranslation("website");
																return (
																	<>
																	{showEmailSendPopup ? (
																		<div className="verify-warning-holder verify__succed" style={router.route === "/" ? {} : { width: "100%", position: "absolute" }}>
																		{t("common.header.confirmationemailsent")}
																		</div>
																		) : (
																			currentUser?.userType === 3 &&
																			!currentUser?.isEmailVerify && (
																				<div className="verify-warning-holder" style={router.route === "/" ? {} : { width: "100%", position: "absolute" }}>
																				{t("common.header.Pleaseconfirmyouremail")}
																				<span onClick={() => handelResendVerfiy()}>{t("common.header.Resendemail")}</span>
																				</div>
																				)
																				)}
																				
																				{currentUser?.userType === 3 && currentUser?.isEmailVerify && !currentUser?.isUserProfileCompleted && (
																					<div className="verify-warning-holder" style={router.route === "/" ? {} : { width: "100%", position: "absolute" }}>
																					{t("common.header.pleasecompleteyourpersonalinformation")},<Link href={`/profile/personal-details`}>{t("common.header.complateProfile")}</Link>
																					</div>
																					)}
																					{currentUser?.userType === 3 && currentUser?.isEmailVerify && currentUser?.isUserProfileCompleted && !currentUser?.isCompanyProfileCompleted && (
																						<div className="verify-warning-holder" style={router.route === "/" ? {} : { width: "100%", position: "absolute" }}>
																						{t("common.header.pleasecompleteyourcompanyinformation")},<Link href={`/profile/company-profile`}>{t("common.header.complatecompanyProfile")}</Link>
																						</div>
																						)}
																						</>
																						);
																					};
																					
																					const TopDrawer = ({ visitKsaDrawerOpen, isAdmin, setVisitKsaDrawerOpen }: any) => {
																						const { t } = useTranslation("website");
																						if (localStorage.getItem("theme") === "visitKsa" && !isAdmin) {
																							return (
																								<>
																								<Drawer
																								className="header-visitksa-drawer"
																								contentWrapperStyle={{ height: "auto" }}
																								closable={false}
																								onClose={() => setVisitKsaDrawerOpen(false)}
																								footer={null}
																								placement="top"
																								open={visitKsaDrawerOpen}
																								>
																								<VisitKsaTabs isInDrawer onClose={() => setVisitKsaDrawerOpen(false)} />
																								</Drawer>
																								
																								<div className="header-visitksa-explore" onClick={() => setVisitKsaDrawerOpen(true)}>
																								<p>{t("common.buttons.explore")}</p>
																								{/* <VisitKsaSearchIcon /> */}
																								<MdTravelExplore className="main-color" style={{ fontSize: 22 }} />
																								</div>
																								</>
																								);
																							}
																							return <></>;
																						};
																						const DesktopView = ({
																							setisMenuVisible,
																							sectionLeft,
																							isLastStep,
																							currentTheme,
																							isArabicLang,
																							currentUser,
																							partnerClickHandler,
																							loginFunction,
																							translate,
																							isAdmin,
																							hideOtaBtn,
																							visitKsaDrawerOpen,
																							setVisitKsaDrawerOpen
																						}: any) => {
																							const { t } = useTranslation("website");
																							// const { data } = useGetCartDetails();
																							// console.log(data?.responseData, "data?.responseDatadata?.responseDatadata?.responseData");
																							const router = useRouter();
																							
																							return (
																								<div className="container">
																								<div className="header-sub-container">
																								<MenuOutlined
																								onClick={() => {
																									setisMenuVisible(true);
																									setTimeout(() => {
																										document?.querySelector(".home-res-menu")?.classList.add("active");
																									}, 0);
																								}}
																								className="menu-bars-holder"
																								/>
																								<LeftSection sectionLeft={sectionLeft} isLastStep={isLastStep} currentTheme={currentTheme} isArabicLang={isArabicLang} />
																								<div className="Header_Right">
																								<TopDrawer visitKsaDrawerOpen={visitKsaDrawerOpen} isAdmin={isAdmin} setVisitKsaDrawerOpen={setVisitKsaDrawerOpen} />
																								<div className="Header_Right_Options">
																								<div className="header_preference">
																								<CurrencyModal translate={translate} />
																								{<LanguageDropdown translate={translate} />}
																								</div>
																								{!currentUser && !hideOtaBtn && (
																									<Button onClick={partnerClickHandler} type="primary" ghost className="Header_Right_ListService ant-btn-md">
																									{t("common.header.listyourservice")}
																									</Button>
																									)}
																									</div>
																									{currentUser ? (
																										<>
																										{/* <Dropdown
																										trigger={["click"]}
																										getPopupContainer={() => document.querySelector(".header-container")}
																										rootClassName="menu-items-wrapper"
																										menu={{
																											items: data
																											? [
																												{
																													label: (
																														<div className="menu-header-content">
																														<h4 className="menu-header--title">{t("common.sentence.myCart")} </h4>
																														<p className="menu-header--desc">
																														<strong>{data?.length}</strong>
																														{t("common.sentence.items")}
																														</p>
																														</div>
																														),
																														className: "menu-header-wrapper",
																														key: "0"
																													},
																													...data?.map((item: any) => ({
																														key: item?.id,
																														label: <CartItemMenu data={item} />
																													})),
																													{
																														label: (
																															<div className="menu-footer-content">
																															<Button
																															type="primary"
																															ghost
																															block
																															onClick={() => {
																																router.push("/cart");
																															}}
																															>
																															{t("common.buttons.viewCart")}
																															</Button>
																															<Button
																															type="primary"
																															block
																															className="green-btn"
																															onClick={() => {
																																router.push("/cart/checkout");
																															}}
																															>
																															{t("common.buttons.checkout")}
																															</Button>
																															</div>
																															),
																															className: "menu-footer-wrapper",
																															key: "1"
																														}
																													]
																													: []
																												}}
																												>
																												<Badge count={data?.length} size="small">
																												<MdOutlineShoppingCart style={{ color: "#949E89", fontSize: 23 }} />
																												</Badge>
																											</Dropdown> */}
																											<ProfileDropdown translate={translate} />
																											</>
																											) : (
																												<Button type="primary" className="header_login_button ant-btn-md" onClick={loginFunction}>
																												{t("common.header.Login")}
																												</Button>
																												)}
																												</div>
																												</div>
																												</div>
																												);
																											};
																											
																											export default Header;
																											
																											const CustomDivider = styled.div`
																											height: 1px;
																											width: 100%;
																											background-color: #e5e5e5;
																											`;
																											