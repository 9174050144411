import styled from "@emotion/styled";
import { radiusInputCheck, sharedInputStyle } from "./shared";

export const RoundedInputNumberStyle = styled.div<{ roundDir?: "left" | "right" | "both" | undefined }>`
	${sharedInputStyle}

	border-radius: ${({ roundDir }) => radiusInputCheck(roundDir)};
	&:lang(ar) {
		border-radius: ${({ roundDir }) => radiusInputCheck(roundDir === "left" ? "right" : roundDir === "right" ? "left" : roundDir)};
	}
	width: 210px;
	height: 40px;
	gap: 10px;

	@media only screen and (max-width: 900px) {
		width: 100%;
		border-radius: ${({ roundDir }) => radiusInputCheck("both")} !important;
		&:lang(ar) {
			border-radius: ${({ roundDir }) => radiusInputCheck("both")} !important;
		}
	}

	.ant-input-number-group-wrapper {
		border-radius: ${({ roundDir }) => radiusInputCheck(roundDir)};
		border: none !important;
		width: 75%;
		&:lang(ar) {
			border-radius: ${({ roundDir }) => radiusInputCheck(roundDir === "left" ? "right" : roundDir === "right" ? "left" : roundDir)};
		}

		.ant-input-number {
			border: none !important;
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}
		.ant-input-number-input-wrap {
			input {
				color: #595959 !important;
				text-align: center;
			}
		}

		.ant-input-number-focused {
			outline: none;
			box-shadow: none;
		}
		.ant-input-number-group-addon {
			background-color: transparent;
			border: none;
			span {
				font-size: 20px;
				color: ${({ theme }) => theme.token.colorPrimaryActive};
			}
		}
	}
	.btn {
		height: auto;
		background: transparent;
		border: none;
		box-shadow: none !important;
		padding: 0;
	}
`;
