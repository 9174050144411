import styled from "@emotion/styled";
import { Select } from "antd";
import { radiusInputCheck, sharedInputStyle } from "./shared";

export const RoundedSelectStyle = styled.div<{ roundDir?: "left" | "right" | "both"; width?: number }>`
	${sharedInputStyle}
	height: 40px;

	&:lang(ar) {
		padding-right: 15px;
		padding-left: 0px;
	}

	@media only screen and (max-width: 900px) {
		width: 100% !important;
		border-radius: ${({ roundDir }) => radiusInputCheck("both")} !important;

		&:lang(ar) {
			border-radius: ${({ roundDir }) => radiusInputCheck("both")} !important;
		}
	}

	/* .visit-ksa-hero-bar-select { */
	width: ${({ width }) => width ?? 246}px;

	border-radius: ${({ roundDir }) => radiusInputCheck(roundDir)};
	height: 40px;
	&:lang(ar) {
		border-radius: ${({ roundDir }) => radiusInputCheck(roundDir === "left" ? "right" : roundDir === "right" ? "left" : roundDir)};
	}

	@media only screen and (max-width: 900px) {
		border-radius: ${({ roundDir }) => radiusInputCheck("both")} !important;
		/* comment overflow cuz it hidden dropdown list */
		.ant-select-single {
			white-space: nowrap;
			/* overflow: hidden; */
			text-overflow: ellipsis;
			max-width: 260px;
		}
	}
	.popup {
		width: 260px !important;
		left: 166px !important;
	}
	@media only screen and (max-width: 900px) {
		border-radius: 20px !important;
	}

	.ant-select-selection-placeholder {
		color: #343434;
	}
	.ant-select {
		width: 100%;
		border: none !important;
		border-radius: 0;
		.ant-select-selector {
			@media only screen and (max-width: 900px) {
				border-radius: 20px !important;
			}
			border: none !important;
			border-radius: 0;
		}
	}
	.ant-select-selector {
		box-shadow: none !important;
		border-radius: 32px !important;
	}
	/* } */
`;
