import { VisitKsaTransportationVehicleIcon } from "@components/visitKsa/VisitKsaIcon";
import { Button, DatePicker, Form, InputNumber, Select, Space } from "antd";
import { useTranslation } from "next-i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { RoundedDate } from "../styles/RoundedDate";
import { RoundedInputNumberStyle } from "../styles/RoundedInputNumber";
import { RoundedSelectStyle } from "../styles/RoundedSelect";
import { VisitKsaTransportationTabStyle } from "./VisitKsaTransportationStyle";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import VisitKsaTabBtn from "../styles/VisitKsaTabBtn";
import dayjs from "dayjs";
import { RangePickerProps } from "antd/es/date-picker";
import { useGetRoutes, useGetVehicleTypes } from "@queries/masterdata/useMasterdata";
import { useRouter } from "next/router";
import { VisitKsaTabInputsStyle, VisitKsaInputErrorMessage } from "../styles/VisitKsaTabInputsStyle";
import { MdOutlineCalendarToday, MdOutlineGroupAdd, MdRoute } from "react-icons/md";
// import { VisitKsaInputErrorMessage } from "../styles/VisitksaInputErrorMessage";

interface VisitKsaTransportationTabProps {
	onSearch?: (values?: any) => void;
}

const { Option } = Select;
const VisitKsaTransportationTab: React.FC<VisitKsaTransportationTabProps> = ({ onSearch }) => {
	const { t, i18n } = useTranslation("website");
	const isArabicLang = i18n.language === "ar";
	const router = useRouter();
	const { data: routes } = useGetRoutes({ lang: i18n.language, isEnable: true });
	const { data: vechicleTypes } = useGetVehicleTypes({ lang: i18n.language, isEnable: true });
	const [form] = Form.useForm();
	const [passengersCount, setPassengersCount] = React.useState(1);
	const [startDate, setStartDate] = useState(null);
	const [routeCode, setRouteCode] = useState(null);
	const [vehicleTypeCode, setVehicleTypeCode] = useState(null);
	const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
		return current && current < dayjs().startOf("day").add(4, "days");
	};
	const onFinish = (values: any) => {
		let queryParams: any = {
			searchType: 2,
			quantity: 1,
			isB2c: false,
			model: {
				from: 2000,
				to: 2023
			}
		};
		queryParams.noOfPax = passengersCount || 1;
		if (routeCode) {
			queryParams.routeCode = routeCode;
			queryParams.routeName = routes?.find((r: any) => r?.code === routeCode)?.name;
		}
		if (vehicleTypeCode && vehicleTypeCode !== 0) queryParams.vehicleTypeCode = vehicleTypeCode;
		if (startDate) queryParams.startDate = dayjs(startDate).locale("en").format("YYYY-MM-DD");

		onSearch && onSearch();
		router.push(
			{
				pathname: `/search/umrah`,
				query: queryParams
			},
			{
				pathname: `/search/umrah`,
				query: queryParams
			},
			{ locale: isArabicLang ? "ar" : "en" }
		);
	};

	return (
		<VisitKsaTransportationTabStyle>
			<Form form={form} className="visit-ksa-transportation-tab-form" onFinish={onFinish}>
				{(_, formInstance) => {
					return (
						<>
							<VisitKsaTabInputsStyle>
								<Form.Item
									noStyle
									name="routeCode"
									rules={[
										{
											required: true
										}
									]}
								>
									<RoundedSelectStyle roundDir="left">
										<MdRoute style={{ fontSize: 20, color: "#949E89" }} />
										<Select
											placeholder={t("forms.fields.labels.route")}
											showArrow={false}
											value={routeCode}
											onChange={(val: any, option: any) => {
												formInstance.setFieldValue("routeCode", val);
												setRouteCode(val);
											}}
										>
											{Array.isArray(routes) &&
												routes?.map((s: any) => (
													<Option type={"Location"} value={s?.code}>
														{s?.name}
													</Option>
												))!}
										</Select>
										{!formInstance.getFieldValue("routeCode") && formInstance.getFieldError("routeCode") && (
											<VisitKsaInputErrorMessage>{formInstance.getFieldError("routeCode")}</VisitKsaInputErrorMessage>
										)}
									</RoundedSelectStyle>
								</Form.Item>
								<Form.Item
									noStyle
									name="date"
									rules={[
										{
											required: true
										}
									]}
								>
									<RoundedDate width={250}>
										<MdOutlineCalendarToday style={{ fontSize: 20, color: "#949E89" }} />
										<DatePicker
											disabledDate={disabledDate}
											onChange={(date) => {
												formInstance.setFieldValue("date", date);
												setStartDate(date);
											}}
										/>
										{!formInstance.getFieldValue("date") && formInstance.getFieldError("date") && <VisitKsaInputErrorMessage>{formInstance.getFieldError("date")}</VisitKsaInputErrorMessage>}
									</RoundedDate>
								</Form.Item>
								<RoundedInputNumberStyle>
									<MdOutlineGroupAdd style={{ fontSize: 20, color: "#949E89" }} />
									<InputNumber
										value={passengersCount}
										onChange={(value: any) => setPassengersCount(value)}
										addonBefore={
											<Button className="btn">
												<MinusCircleFilled
													disabled={passengersCount <= 1}
													onClick={() => {
														if (passengersCount > 1) {
															setPassengersCount((prev: number) => prev - 1);
														}
													}}
												/>
											</Button>
										}
										addonAfter={
											<Button className="btn">
												<PlusCircleFilled onClick={() => setPassengersCount((prev: number) => prev + 1)} />
											</Button>
										}
										placeholder="Passengers"
										min={1}
									/>
								</RoundedInputNumberStyle>

								<div className="vist-ksa-side-input">
									<Form.Item name="vechicleType" noStyle>
										<RoundedSelectStyle roundDir="right" width={221} className="visit-ksa-transportation-tab-vechicle-select">
											<VisitKsaTransportationVehicleIcon />
											<Select
												placeholder={t("forms.fields.labels.vechicleType")}
												showArrow={false}
												// filterOption={(input, option) => searchDestinationsItems}
												// value={destinations ? destination || destination?.value || userLocation?.passengersryName || destinations?.[0]?.id : null}
												defaultValue={0}
												value={vehicleTypeCode || 0}
												onChange={(val: any, option: any) => {
													formInstance.getFieldValue("vechicleType", val);
													setVehicleTypeCode(val);
												}}
												// onSearch={debounce((val: string) => setDestinationsSearch(val), 300)}
												// loading={destinationsLoading || isSearchDestinationsLoading}
												popupClassName="vechicle-popup"
												getPopupContainer={(triggerNode) => triggerNode.parentElement}
												dropdownStyle={{ width: "auto" }}
											>
												<Option type={"Location"} value={0}>
													<b>{t(`profile.personal.all`)}</b>
												</Option>
												{Array.isArray(vechicleTypes) &&
													vechicleTypes?.map((s: any) => (
														<Option type={"Location"} value={s?.code}>
															<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", minWidth: "200px" }}>
																<b>{s?.name}</b>
																<div>
																	<span>{t(`sections.transportation.max capacity`)} : </span> <b>{s?.maxCapacity}</b>
																</div>
															</div>
														</Option>
													))!}
											</Select>
										</RoundedSelectStyle>
									</Form.Item>
									<VisitKsaTabBtn htmlType="submit" className="grid-btn" />
								</div>
							</VisitKsaTabInputsStyle>
							<VisitKsaTabBtn htmlType="submit" className="main-btn" />
						</>
					);
				}}
			</Form>
		</VisitKsaTransportationTabStyle>
	);
};

export default VisitKsaTransportationTab;
