import { Alert, Button, Form, Input, message } from "antd";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { signIn } from "next-auth/react";
import { register } from "@controllers/state/auth/actions";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AuthClear } from "store/reducers/authentication/authentication";
import { fetchUserInfo } from "store/reducers/user";
import { useAppDispatch } from "store/configureStore";
import FormRegisteration from "./style/FormRegisteration";
import { uTypes } from "@components/profile/package/types/UserTypes";
type Props = { userType: number; fieldsArr: any[]; submitBtnKey: string; initialValues?: any };
const RegisterForm: React.FC<Props> = ({ userType, fieldsArr, submitBtnKey, initialValues }) => {
	const { t, i18n } = useTranslation("website");
	let { auth }: any = useSelector((state) => state);
	const dispatch = useAppDispatch();
	const router = useRouter();
	const [customLoadingState, setCustomLoadingState] = useState(false);
	const ISSERVER = typeof window === "undefined";
	let isThereRedirectPath: any = ISSERVER ? null : localStorage.getItem("on_login_redirect_path");
	let isArabicLang = i18n.language === "ar";
	const [form] = Form.useForm();

	useEffect(() => {
		return () => {
			dispatch(AuthClear());
		};
	}, [router.pathname]);
	const handleSubmit = async (dataItem: { [name: string]: any }) => {
		const { userName, password, companyName } = dataItem;
		let body: any = {
			userName,
			password,
			userType,
			clientId: "WebSite",
			clientSecret: "V0lUz9dgaPCDej2H1CTQLO26jmNlnh8d",
			deviceToken: "string",
			deviceId: "string",
			isNewUser: true,
			...(userType === 3 && { companyName })
		};
		dispatch(
			register(body, (res: any) => {
				dispatch(fetchUserInfo(res.responseData?.accessToken)).then(() =>
					signIn("Credentials", {
						...(isThereRedirectPath && (+userType === uTypes.B2B || +userType === uTypes.B2C) && { callbackUrl: isArabicLang ? "/ar" + isThereRedirectPath : isThereRedirectPath }),
						...(!isThereRedirectPath && {
							callbackUrl: isArabicLang ? "/ar/profile/personal-details" : "/profile/personal-details"
						}),
						...res?.responseData
					})
				);
			})
		);
	};

	const [messageApi, contextHolder] = message.useMessage();

	useEffect(() => {
		if (router.query.error) {
			messageApi.open({
				type: "error",
				content: router.query.error
			});
		}
	}, [router.query.error]);

	useEffect(() => {
		{
			if (auth?.error[0]) {
				form.setFields([
					{
						name: "userName",
						errors: auth?.error
					}
				]);
			}
		}
	}, [auth?.error[0]]);

	return (
		<FormRegisteration>
			{contextHolder}
			<Form
				form={form}
				layout="vertical"
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 24 }}
				initialValues={{ remember: true, companyName: initialValues?.companyName }}
				onFinish={handleSubmit}
				autoComplete="off"
			>
				{fieldsArr.map((field) => (
					<Form.Item
						className="input-holder"
						label={t(`forms.fields.labels.${field.label}`)}
						key={field.name}
						name={field.name}
						rules={[
							...(Array.isArray(field?.validator)
								? field.validator?.map((e: any) => {
										return {
											...e,
											message: t(`forms.validations.${e.message}`)
										};
								  })
								: []),
							...(Array.isArray(field.validator) && field?.validator?.find((e: { customValidator: string }) => e.customValidator === "passSame")
								? [
										({ getFieldValue }: any) => ({
											validator(_: any, value: any) {
												if (!value || getFieldValue("password") === value) {
													// eslint-disable-next-line no-undef
													return Promise.resolve();
												}
												// eslint-disable-next-line no-undef
												return Promise.reject(new Error(t(`forms.validations.passNotSame`)));
											}
										})
								  ]
								: [])
						]}
					>
						{field.type === "password" ? (
							<Input.Password required={field.required} placeholder={t(`forms.fields.placeholders.${field.placeholder}`)} />
						) : (
							<Input type={field.type} required={field.required} placeholder={t(`forms.fields.placeholders.${field.placeholder}`)} />
						)}
					</Form.Item>
				))}
				<Button loading={auth?.loading || customLoadingState} type="primary" htmlType="submit">
					<div>{t(`common.buttons.${submitBtnKey}`)}</div>
				</Button>
			</Form>
		</FormRegisteration>
	);
};

export default RegisterForm;
