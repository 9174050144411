import { useTranslation } from "next-i18next";
import React from "react";
import { OTACompaniesIcon, StaysIcon, TransfersIcon, VisitKsaToolbarThingsToDoIcon, VisitKsaToolbarUmrahIcon, VisitKsaTourTripIcon, VisitKsaUmrahJourneyIcon } from "../VisitKsaIcon";
import { VisitKsaToolbarStyle } from "./VisitKsaToolbarStyle";
import { PlusCircleOutlined } from "@ant-design/icons";
import Link from "next/link";
import { useRouter } from "next/dist/client/router";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import Api from "@config/network/ApiConfig";
import { Tooltip } from "antd";

interface VisitKsaToolbarProps {}

const VisitKsaToolbar: React.FC<VisitKsaToolbarProps> = () => {
	const { t } = useTranslation("website");
	const router = useRouter();
	const userLocation = useSelector((state: any) => state?.user?.userLocation);
	const currentUser = useSelector((state: RootState) => state?.user?.currentUser);

	const onTourTripClick = () => {
		let queryParams: any = {};
		queryParams.numberOfSeats = 1;
		router.push({
			pathname: "/p",
			query: queryParams
		});
	};
	const onThingsToDoClick = () => {
		let queryParams: any = {};
		queryParams.numberOfSeats = 1;
		router.push({
			pathname: "/things-to-do",
			query: queryParams
		});
	};
	const onPartinerDoClick = () => {
		router.push("/ourPartners");
	};

	const currentTheme =
		localStorage?.getItem("TenantConfiguration") && localStorage?.getItem("TenantConfiguration") !== "undefined" ? JSON?.parse(localStorage?.getItem("TenantConfiguration") || "{}") : null;

	return (
		<VisitKsaToolbarStyle>
			<div className="container">
				<div className="visit-bar-wrapper">
					<div className="visit-bar-content">
						{currentTheme?.features?.isPackageActive && (
							<div className="visit-ksa-sub-header-btn" onClick={onTourTripClick}>
								<VisitKsaTourTripIcon />
								<p>{t("profile.package.Packages")}</p>
							</div>
						)}
						{currentTheme?.features?.isThingsToDoActive && (
							<div className="visit-ksa-sub-header-btn" onClick={onThingsToDoClick}>
								<VisitKsaToolbarThingsToDoIcon />
								<p>{t("common.buttons.thingsToDo")}</p>
							</div>
						)}
						{currentTheme?.features?.isUmrahJourneyActive && (
							<div className="visit-ksa-sub-header-btn" onClick={() => router.push("/umrah/start")}>
								<VisitKsaToolbarUmrahIcon />
								<p>
									{" "}
									{t("common.buttons.umrahJourney")} + {t("common.sentence.visa")}
								</p>
							</div>
						)}
						{currentTheme?.tenantID?.toLowerCase() === "5d33b7e6-eabe-4c17-1f9a-08db1410efcc" && (
							<>
								<div
									className="visit-ksa-sub-header-btn"
									onClick={() => {
										// console.log(currentUser, "currentUser");
										// if (currentUser) {
										// 	window.open("http://More.dyafat.com");
										// 	return false;
										// }
										if (currentUser && currentUser?.userType === 3 && !currentUser?.isCompanyApproved) {
											return false;
										}
										Api.get("AuthenticationAPI", "/Authentication/CreateExternalUrl")
											.then((res: any) => {
												window.open(res);
											})
											.catch((e: { message: any }) => {
												// message.error(e?.message);
											});
									}}
								>
									<Tooltip
										title={
											currentUser && currentUser?.userType === 3 && !currentUser?.isCompanyApproved
												? t("common.sentence.Please complete your account info. and company profile to access this module")
												: ""
										}
										className="sub-header-btn--tooltip"
									>
										<StaysIcon />
										<p>{t("common.stays")}</p>
									</Tooltip>
								</div>
								<div
									className="visit-ksa-sub-header-btn"
									onClick={() => {
										// if (currentUser) {
										// 	window.open("http://More.dyafat.com");
										// 	return false;
										// }
										if (currentUser && currentUser?.userType === 3 && !currentUser?.isCompanyApproved) {
											return false;
										}
										Api.get("AuthenticationAPI", "/Authentication/CreateExternalUrl")
											.then((res: any) => {
												window.open(res);
											})
											.catch((e: { message: any }) => {});
									}}
								>
									<Tooltip
										title={
											currentUser && currentUser?.userType === 3 && !currentUser?.isCompanyApproved
												? t("common.sentence.Please complete your account info. and company profile to access this module")
												: ""
										}
										className="sub-header-btn--tooltip"
									>
										<TransfersIcon />
										<p>{t("common.buttons.transfers")}</p>
									</Tooltip>
								</div>
							</>
						)}
					</div>
					<div className="visit-ksa-sub-header-btn" onClick={onPartinerDoClick}>
						<OTACompaniesIcon />
						<p>{t("common.header.OTACompanies")}</p>
					</div>
				</div>
			</div>
		</VisitKsaToolbarStyle>
	);
};

export default VisitKsaToolbar;
