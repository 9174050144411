import {
	AdministrationIcon,
	AdminSideMenuIcon,
	CompanySidePanelIcon,
	DashboardIcon,
	LinearHeart,
	LinearMessage,
	LinearProfileCircle,
	LinearTicket,
	PackageIcon,
	PackagesSidePanelIcon,
	ProfileIcon,
	Receiptminus,
	Refresh,
	SettingIcon,
	SupplierTabIcon,
	Group15936,
	WalletIcon,
	LogoutIcon
} from "@common/icons";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { setCompanyProfile, setCurrentUser } from "store/reducers/user";
import { useAppDispatch } from "store/configureStore";
import { toggleAuthModal } from "store/reducers/authentication/authentication";
import { Avatar, Divider, Drawer, Menu, MenuProps, Space } from "antd";
import { CloseOutlined, LogoutOutlined } from "@ant-design/icons";
import { FilesAPI } from "@common/constants";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";

const MobileSideMenu = ({ open, closeDrawer }: { open: boolean; closeDrawer: VoidFunction }) => {
	const { t, i18n } = useTranslation("website");

	const isArabicLang = i18n.language === "ar";
	const router = useRouter();
	const { asPath } = router;
	const dispatch = useAppDispatch();
	const currentUser = useSelector((state: RootState) => state?.user?.currentUser);
	const { data: user } = useSession();
	const logoutFunction = () => {
		signOut({ redirect: true }).then(() => {
			dispatch(toggleAuthModal(false));
			dispatch(setCurrentUser(null));
			dispatch(setCompanyProfile(null));
			router.replace(asPath, asPath, { locale: isArabicLang ? "ar" : "en" });
		});
	};
	type MenuItem = Required<MenuProps>["items"][number];

	function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: "group"): MenuItem {
		return {
			key,
			icon,
			children,
			label,
			type
		} as MenuItem;
	}

	const checkIsAuthorized = (roleName: any) => {
		const userRoles = currentUser?.roles || user?.roles;
		return userRoles?.some((role: any) => role === roleName);
	};

	const checkIsAuthorizedGroup = (roleList: any) => {
		const userRoles = currentUser?.roles || user?.roles;
		return roleList?.some((role: any) => userRoles?.includes(role));
	};
	const endCustomerItems = [
		getItem(
			t(`profile.personal.PersonalInfo`),
			"PersonalInfo",
			<Link href="/profile/personal-details">
				<ProfileIcon />
			</Link>
		),
		// getItem(
		//     t(`profile.personal.Paymentdetails`),
		//     "Paymentdetails",
		//     <Link href="/profile/payment_details">
		//         <ProfileIcon />
		//     </Link>
		// ),
		getItem(t(`profile.personal.Mybookings`), "Mybookings", <LinearTicket />, [
			// getItem(<Link href="/profile/bookings/hotels">{t(`profile.personal.hotels`)}</Link>, "hotels"),
			getItem(<Link href="/profile/Bookings">{t(`profile.personal.Packages`)}</Link>, "packages")
			// getItem(<Link href="/profile/bookings/umrah">{t(`profile.personal.umrah`)}</Link>, "umrah")
		]),
		getItem(
			t("common.header.Logout"),
			"Logout",
			<a onClick={logoutFunction} className="danger-txt">
				<LogoutIcon />
			</a>
		)
	];

	const bussinesItems: MenuProps["items"] = [
		checkIsAuthorized("Dashboard") &&
			getItem(
				t(`profile.personal.dashboard`),
				"dashboard",
				<Link href="/profile/dashboard">
					<DashboardIcon />
				</Link>
			),
		{
			type: "divider"
		},

		checkIsAuthorized("Packages") &&
			getItem(
				t(`profile.personal.Packages`),
				"packages",
				<Link href="/profile/packages">
					<PackageIcon />
				</Link>
			),
		{
			type: "divider"
		},

		checkIsAuthorizedGroup(["PackageBookings"]) &&
			getItem(t(`profile.personal.Mybookings`), "Mybookings", <LinearTicket />, [
				checkIsAuthorized("PackageBookings") && getItem(<Link href="/profile/Bookings">{t(`profile.personal.Packages`)}</Link>, "Bookings"),
				getItem(<Link href="bookings-hotels">{t(`profile.personal.hotels`)}</Link>, "bookings-hotels"),
				getItem(<Link href="bookings-transportation">{t(`profile.personal.Transportations`)}</Link>, "bookings-transportation"),
				getItem(<Link href="bookings-umrah">{t(`profile.personal.umrah`)}</Link>, "bookings-umrah")
			]),
		{
			type: "divider"
		},

		checkIsAuthorized("Suppliers") &&
			getItem(
				t(`profile.personal.suppliers`),
				"suppliers",
				<Link href="/profile/suppliers">
					<SupplierTabIcon />
				</Link>
			),
		{
			type: "divider"
		},

		checkIsAuthorized("Wallet") &&
			getItem(
				t(`profile.personal.wallet`),
				"wallet",
				<Link href="/profile/wallet">
					<WalletIcon />
				</Link>
			),
		{
			type: "divider"
		},

		checkIsAuthorizedGroup(["Members", "Roles"]) &&
			getItem(t(`profile.personal.administration`), "administration", <AdministrationIcon />, [
				checkIsAuthorized("Members") && getItem(<Link href="/profile/members">{t(`profile.personal.members`)}</Link>, "members"),
				checkIsAuthorized("Roles") && getItem(<Link href="/profile/roles">{t(`profile.personal.roles`)}</Link>, "roles")
			]),
		{
			type: "divider"
		},

		getItem(t(`profile.personal.Account Setting`), "setting", <SettingIcon />, [
			getItem(<Link href="/profile/personal-details">{t(`profile.personal.PersonalInfo`)}</Link>, "personal-details"),
			checkIsAuthorized("CompanyProfile") && getItem(<Link href="/profile/company-profile">{t(`profile.personal.Companyprofile`)}</Link>, "company-profile")
		]),
		getItem(
			t("common.header.Logout"),
			"Logout",
			<a onClick={logoutFunction} className="danger-txt">
				<LogoutIcon />
			</a>
		)
	];

	useEffect(() => {
		closeDrawer();
	}, [router]);

	return (
		// <DropdownContainer menu={menu} trigger={["click"]} className="profile_dropdown">
		<div className="header-profile-container">
			<>
				<Drawer title="" placement={isArabicLang ? "right" : "left"} closable={false} open={open} onClose={closeDrawer} className="header-drawer">
					<div className="drawer-header">
						<div className="user-data">
							<Avatar src={`${FilesAPI}/AccessFile/${currentUser?.image}`} style={{ fontSize: 20 }} alt="profile">
								{currentUser?.firstName?.charAt(0)}
							</Avatar>
							<span className="profile_name">{currentUser?.firstName}</span>
						</div>
						<CloseOutlined onClick={closeDrawer} style={{ fontSize: 22 }} />
					</div>
					<Menu style={{ width: "100%" }} mode="inline" items={currentUser?.userType === 2 ? endCustomerItems : bussinesItems}>
						<Divider />
					</Menu>
				</Drawer>
			</>
		</div>
	);
};
export default MobileSideMenu;
