import Services from "@service/auth";
import actionCall from "@config/network/Network";
import * as request from "store/reducers/authentication/authentication";

export const login = (values?: any, redirect?: any, errorHandling?: any) => async (dispatch: any) =>
	actionCall({ service: () => Services.login(values), reqResult: { request, name: "AuthSuccess" } }, dispatch, redirect, errorHandling, false);

export const register = (values?: any, redirect?: any) => async (dispatch: any) =>
	actionCall({ service: () => Services.register(values), reqResult: { request, name: "AuthSuccess" } }, dispatch, redirect, "", false);

export const SetEmailAsConfirmedByToken = (values?: any, redirect?: any, errorHandling?: any) => async (dispatch: any) =>
	actionCall({ service: () => Services.SetEmailAsConfirmedByToken(values), reqResult: { request, name: "AuthSuccess" } }, dispatch, redirect, errorHandling, false);

export const forgetPasswordCode = (values?: any, redirect?: any) => async (dispatch: any) =>
	actionCall({ service: () => Services.forgetPasswordCode(values), reqResult: { request, name: "AuthSuccess" } }, dispatch, redirect, "", false);

export const forgetPasswordURL = (values?: any, redirect?: any) => async (dispatch: any) => {
	console.log(values);
	return actionCall({ service: () => Services.forgetPasswordURL(values), reqResult: { request, name: "AuthSuccess" } }, dispatch, redirect, "", false);
};

export const EmailConfirmationUsingURL = (redirect?: any, errorHandling?: any) => async (dispatch: any) =>
	actionCall({ service: () => Services.EmailConfirmationUsingURL(), reqResult: { request, name: "AuthSuccess" } }, dispatch, redirect, errorHandling, false);

export const resetPassword = (values?: any, redirect?: any, errorHandling?: any) => async (dispatch: any) =>
	actionCall({ service: () => Services.resetPassword(values), reqResult: { request, name: "AuthSuccess" } }, dispatch, redirect, errorHandling, false);

export const verifyEmailConfirmationByToken = (values?: any, redirect?: any, errorHandling?: any) => async (dispatch: any) =>
	actionCall({ service: () => Services.verifyEmailConfirmationByToken(values), reqResult: { request, name: "AuthSuccess" } }, dispatch, redirect, errorHandling, false);

export const VerifyResetPasswordByToken = (values?: any, redirect?: any, errorHandling?: any) => async (dispatch: any) =>
	actionCall({ service: () => Services.VerifyResetPasswordByToken(values), reqResult: { request, name: "AuthSuccess" } }, dispatch, redirect, errorHandling, false);
