import styled from "@emotion/styled";

export const VisitKsaTabInputsStyle = styled.div`
	display: flex;
	@media only screen and (max-width: 900px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-items: end;
		gap: 16px;
		width: 100%;
	}
	@media only screen and (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

export const VisitKsaInputErrorMessage = styled.p`
	position: absolute;
	bottom: -20px;
	font-size: 12px;
	color: red;
	&:lang(en) {
		left: 0;
	}
	&:lang(ar) {
		right: 0;
	}
`;
