import {
	BriefCase,
	LinearHeart,
	LinearMessage,
	LinearProfileCircle,
	LinearTicket,
	ProfileIcon,
	Receiptminus,
	DashboardIcon,
	Wallet2,
	WalletIcon,
	PackagesSidePanelIcon,
	CompanySidePanelIcon
} from "@common/icons";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { setCompanyProfile, setCurrentUser } from "store/reducers/user";
import { useAppDispatch } from "store/configureStore";
import { toggleAuthModal } from "store/reducers/authentication/authentication";
import DropdownContainer from "./DropdownContainer";
import { Avatar, Button, Divider, Drawer, Menu, MenuProps, Space } from "antd";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { FilesAPI } from "@common/constants";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import Link from "next/link";
import { useEffect, useState } from "react";
import useWidth from "@helpers/hooks/useWidth";
import MobileSideMenu from "./SideMenuItems";

const ProfileDropdown: React.FC<{ translate: any }> = ({ translate }) => {
	let { t, i18n } = translate;
	const [openSideMenu, setOpenSideMenu] = useState<boolean>(false);

	const isArabicLang = i18n.language === "ar";
	const router = useRouter();
	const { asPath } = router;
	const dispatch = useAppDispatch();
	const currentUser = useSelector((state: RootState) => state?.user?.currentUser);
	const { data: user } = useSession();
	const { width } = useWidth();

	type MenuItem = Required<MenuProps>["items"][number];

	function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: "group"): MenuItem {
		return {
			key,
			icon,
			children,
			label,
			type
		} as MenuItem;
	}

	const logoutFunction = () => {
		signOut({ redirect: true }).then(() => {
			dispatch(toggleAuthModal(false));
			dispatch(setCurrentUser(null));
			dispatch(setCompanyProfile(null));
			router.replace(asPath, asPath, { locale: isArabicLang ? "ar" : "en" });
		});
	};
	const checkIsAuthorized = (roleName: any) => {
		const userRoles = currentUser?.roles || user?.roles;
		return userRoles?.some((role: any) => role === roleName);
	};
	const profileUpperList = [
		// { id: 2, name: t("profile.personal.dashboard"), link: checkIsAuthorized("Dashboard") ? "/profile/dashboard" : "/profile", icon: DashboardIcon },
		checkIsAuthorized("Dashboard") ? { id: 2, name: t("profile.personal.dashboard"), link: "/profile/dashboard", icon: DashboardIcon } : undefined,
		{ id: 0, name: t("common.header.Account"), link: "/profile", icon: ProfileIcon },
		checkIsAuthorized("Packages") ? { id: 5, name: t("common.header.Packages"), link: "/profile/packages", icon: PackagesSidePanelIcon } : undefined,
		// user?.roles?.some((role: any) => role === "PackageBookings") ? { id: 1, name: t("common.header.Bookings"), link: "/profile/Bookings", icon: LinearTicket } : undefined
		checkIsAuthorized("Wallet") ? { id: 3, name: t("common.header.Mywallet"), link: "/profile/wallet", icon: WalletIcon } : undefined
		// { id: 4, name: t("common.header.Inbox"), link: "", icon: LinearMessage }
	];

	const profileUpperListEndCustomer = [
		{ id: 0, name: t("common.header.Account"), link: "/profile", icon: LinearProfileCircle },
		{ id: 2, name: t("common.header.Bookings"), link: "/profile/Bookings", icon: LinearTicket }
	];

	const profileList = currentUser?.userType === 2 ? profileUpperListEndCustomer : profileUpperList?.filter((s: any) => Boolean(s));
	const menu = (
		<Menu>
			{profileList?.map((el: any) => (
				<Menu.Item key={el?.id} icon={el?.icon()}>
					<Link href={el?.link}>{el?.name}</Link>
				</Menu.Item>
			))}
			<Divider />
			<Menu.Item key={8} onClick={logoutFunction} className="sub_item" danger>
				<p>{t("common.header.Logout")}</p>
			</Menu.Item>
		</Menu>
	);
	useEffect(() => {
		setOpenSideMenu(false);
	}, [router]);

	return (
		<div className="header-profile-container">
			{width > 768 ? (
				<DropdownContainer menu={menu} trigger={["click"]} className="profile_dropdown">
					<Button className="profile_trigger">
						<Space size="middle">
							<Avatar size={32} src={`${FilesAPI}/AccessFile/${currentUser?.image}`} alt="profile">
								{currentUser?.firstName?.charAt(0)}
							</Avatar>
							<span className="profile_name">{currentUser?.firstName}</span>
							<DownOutlined />
						</Space>
					</Button>
				</DropdownContainer>
			) : (
				<>
					<Button className="profile_trigger" onClick={() => setOpenSideMenu(true)}>
						<Space size="large">
							<Avatar size={36} src={`${FilesAPI}/AccessFile/${currentUser?.image}`} style={{ marginInlineEnd: 8 }}>
								{currentUser?.firstName?.charAt(0)}
							</Avatar>
						</Space>
					</Button>
					<MobileSideMenu open={openSideMenu} closeDrawer={() => setOpenSideMenu(false)} />
				</>
			)}
		</div>
	);
};
export default ProfileDropdown;
