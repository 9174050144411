import styled from "@emotion/styled";

const ForgetPassword = styled.div`
    font-weight: 500;
    color: #838383;
    font-size: 12px;
    margin-top: 5px;
    cursor: pointer;
`;
export default ForgetPassword;
