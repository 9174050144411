import { VisitKsaHeroBtnIcon } from "@components/visitKsa/VisitKsaIcon";
import styled from "@emotion/styled";
import { Button, ButtonProps } from "antd";
import { useTranslation } from "next-i18next";
import React from "react";
import { MdTravelExplore } from "react-icons/md";

interface VisitKsaTabBtnProps {
	onClick?: (values?: any) => void;
}

const VisitKsaTabBtn: React.FC<VisitKsaTabBtnProps & ButtonProps> = ({ onClick, ...props }) => {
	const { t } = useTranslation("website");
	return (
		<BtnStyle type="primary" onClick={onClick} {...props}>
			<p>{t("common.buttons.explore")}</p>
			{/* <VisitKsaHeroBtnIcon /> */}
			<MdTravelExplore style={{ fontSize: 26 }} />
		</BtnStyle>
	);
};

export default VisitKsaTabBtn;

const BtnStyle = styled(Button)`
	width: 105px;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 12px 8px 12px 16px;

	/* background: #77b84d; */

	/* border: 1px solid #77b84d; */
	border-radius: 20px;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;

	@media only screen and (max-width: 600px) {
		width: 100%;
	}

	p {
		font-weight: 500;
		font-size: 14px;
	}
`;
