import styled from "@emotion/styled";
import { showAnimation } from "../styles/shared";

export const VisitKsaTourTripTabStyle = styled.div<{ isInDrawer?: boolean }>`
	/* width: 934px; */
	height: 90px;
	background-color: #ffffff;
	padding: 20px;
	gap: 10px;

	@media only screen and (max-width: 600px) {
		width: 100%;
	}

	${showAnimation}
	border-radius: 36px;
	z-index: 20;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-width: 900px) {
		height: auto;
	}

	@media only screen and (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		gap: 20px;
		width: 100%;
	}

	.visit-ksa-tab-inputs {
		display: flex;
		@media only screen and (max-width: 900px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			justify-items: end;
			gap: 16px;
		}
		@media only screen and (max-width: 768px) {
			grid-template-columns: 1fr;
		}
	}
	.ant-input-number,
	.ant-picker,
	.ant-select {
		border: none;
		border: 1px solid #d6d9d3;

		&-focused {
			box-shadow: none;
			outline: none;
		}
	}

	.visit-ksa-hero-bar-btn {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 12px 8px 12px 16px;
		gap: 10px;

		width: 105px;
		height: 40px;

		/* background: #77b84d; */

		/* border: 1px solid #77b84d; */
		border-radius: 20px;

		/* Inside auto layout */

		flex: none;
		order: 1;
		flex-grow: 0;

		p {
			font-weight: 500;
			font-size: 14px;
		}
	}

	.grid-btn {
		display: none;
		@media only screen and (max-width: 900px) {
			display: flex;
		}
	}
	.main-btn {
		@media only screen and (max-width: 900px) {
			display: none;
		}
	}
`;
