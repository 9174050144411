import Http from "@config/network/http";
import { AuthenticationAPI as API } from "@common/constants";

const Services = {
	// POST
	authFacebook: async (credentials: any) => await Http.POST(`${API}/Account/FacebookLogin`, credentials),
	authGoogle: async (credentials: any) => await Http.POST(`${API}/Account/GoogleLogin`, credentials),
	login: async (credentials: any) => await Http.POST(`${API}/Authentication/Token`, credentials),
	register: async (credentials: any) => await Http.POST(`${API}/Account/Register`, credentials),
	SetEmailAsConfirmedByToken: async (credentials: any) => await Http.POST(`${API}/Account/VerifyEmailConfirmationByToken`, credentials),
	refreshToken: async (credentials: any) => await Http.POST(`${API}/Authentication/Refresh`, credentials),
	resetPassword: async (credentials: any) => await Http.POST(`${API}/Account/ResetPasswordByToken`, credentials),
	verifyEmailConfirmationByToken: async (credentials: any) => await Http.POST(`${API}/Account/verifyEmailConfirmationByToken`, credentials),
	VerifyResetPasswordByToken: async (credentials: any) => await Http.POST(`${API}/Account/VerifyResetPasswordByToken`, credentials),

	// GET
	forgetPasswordURL: async (credentials: any) => await Http.GET(`${API}/Account/ForgetPasswordUsingURL?ForgetPasswordField=${credentials?.ForgetPasswordField}`),
	EmailConfirmationUsingURL: async () => await Http.GET(`${API}/Account/EmailConfirmationUsingURL`),
	forgetPasswordCode: async (credentials: any) => await Http.GET(`${API}/Account/ForgetPasswordUsingCode?ForgetPasswordField=${credentials?.ForgetPasswordField}`)
};

export default Services;
