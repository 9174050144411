import styled from "@emotion/styled";

const ForgetPassword = styled.div`
    .e-Error {
        color: #ff422f;
    }
    form {
        .input-holder {
            margin-bottom: 1rem;
            .ant-form-item-label {
                max-width: 100%;
                min-width: 100%;
                font: normal normal medium 12px/218px Poppins;

                label {
                    width: 100%;
                    /* white-space: nowrap; */
                }
            }
        }
        button {
            margin-top: 3rem;
        }
    }
`;
export default ForgetPassword;
