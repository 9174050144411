import styled from "@emotion/styled";
import { sharedInputStyle, sharedInputWrapperStyle, showAnimation } from "../styles/shared";

export const VisitKsaVisaTabStyle = styled.div`
	${showAnimation}
	${sharedInputWrapperStyle}
		display: flex;
	align-items: center;

	.ant-form {
		width: 100%;
	}
	.visit-ksa-visa-tab-inputs {
		@media only screen and (max-width: 900px) {
			grid-template-columns: 1fr min-content;
		}

		@media only screen and (max-width: 768px) {
			grid-template-columns: 1fr;
		}
	}
	.visit-ksa-visa-tab-radio-wrapper {
		@media only screen and (max-width: 900px) {
			grid-column: 1/2;
			width: 100%;
			border-radius: 20px;
		}
		${sharedInputStyle}
		flex: 1;
		height: 40px;
		border-radius: 0 20px 20px 0;
		&:lang(ar) {
			border-radius: 20px 0 0 20px;
		}
		@media only screen and (max-width: 900px) {
			height: auto;
		}
	}

	.visit-ksa-visa-tab-btn {
		margin-inline-start: 20px;
	}
`;
