import { css } from "@emotion/react";

export const sharedInputStyle = css`
	background-color: #ffffff;
	display: flex;
	align-items: center;
	padding-left: 15px;
	border: 1px solid #d6d9d3;
	position: relative;

	&:lang(ar) {
		padding-right: 15px;
		padding-left: 0px;
	}

	@media only screen and (max-width: 768px) {
		width: 100% !important;
		border-radius: 20px !important;
	}
`;

export const showAnimation = css`
	animation: show 0.4s ease-in-out forwards;

	@keyframes show {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;

export const hideAnimation = css`
	animation: show 0.4s ease-in-out forwards;

	@keyframes show {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
`;
export const radiusInputCheck = (roundDir: "left" | "right" | "both" | undefined) => {
	if (!roundDir) return "0 !important";
	if (roundDir === "left") return "20px 0px 0px 20px";
	if (roundDir === "right") return "0px 20px 20px 0px";
	if (roundDir === "both") return "20px";
};

export const sharedInputWrapperStyle = css`
	height: 90px;
	background-color: #ffffff;
	padding: 20px;
	gap: 10px;
	@media only screen and (max-width: 900px) {
		height: auto;
	}
`;
