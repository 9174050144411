import styled from "@emotion/styled";

export const VisitKsaTabsStyle = styled.div<{ size: "normal" | "large"; isInDrawer?: boolean }>`
	box-shadow: ${({ isInDrawer }) => (isInDrawer ? "none" : "0px 0px 22px rgba(0, 0, 0, 0.12)")};
	border-radius: 36px;
	z-index: 100;
	background-color: white;

	@media only screen and (max-width: 900px) {
		margin: 0 auto;
		width: 100%;
	}
	@media only screen and (max-width: 900px) {
		width: calc(100% - 15px); // 15px is the padding of the container
	}
	.ant-tabs-nav-wrap {
		padding-inline: 70px;
		border-bottom: 1px solid white;
		@media only screen and (max-width: 768px) {
			padding-inline: 10px;
			border-radius: 36px 36px 0 0;
			.ant-tabs-tab {
				margin-left: 10px;
			}
		}
	}

	.ant-tabs-nav {
		margin: 0;
	}
	.ant-tabs-tab {
		padding: 0;
		p {
			padding: 12px 24px;
			@media only screen and (max-width: 768px) {
				padding: 12px 12px;
				font-size: 12px;
			}
		}
	}

	.ant-tabs-content-holder {
		width: ${({ size }) => (size === "normal" ? "934px" : "1128px")};
		transition: width 0.2s ease-in-out;

		@media only screen and (max-width: 900px) {
			width: 100%;
		}
	}
`;
